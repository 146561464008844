import "./assets/scss/main.scss";
import "./i18n";

import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";

import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { combineReducers, Reducer } from "redux";
import thunk from "redux-thunk";

import { IAppState } from "./interfaces";

import { configureStore } from "@reduxjs/toolkit";
import { isDevelopment, port } from "./config";
import authReducer from "./store/reducers/authReducer";
import expressCheckoutReducer from "./store/reducers/expressCheckoutReducer";
import insuranceReducer from "./store/reducers/insuranceReducer";
import priceQuoteReducer from "./store/reducers/priceQuoteReducer";
import stationReducer from "./store/reducers/stationReducer";
import customerReducer from "./store/reducers/customerReducer";

const rootReducer: Reducer<IAppState> = combineReducers({
  auth: authReducer,
  expressCheckout: expressCheckoutReducer,
  insurance: insuranceReducer,
  station: stationReducer,
  priceQuote: priceQuoteReducer,
  customer: customerReducer,
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(thunk),
  devTools: process.env.NODE_ENV === "development" ? true : false,
});

let BASE_NAME = "/checkout";
if (isDevelopment && port === "3000") {
  BASE_NAME = "";
}

export type TAppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Suspense fallback="loading">
    <Provider store={store}>
      <BrowserRouter basename={BASE_NAME}>
        <App />
      </BrowserRouter>
    </Provider>
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
