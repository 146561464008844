export enum ActionTypes {
  STORE_AUTH_REDIRECT,
  AUTH_START,
  AUTH_SUCCESS,
  AUTH_FAIL,
  LOGOUT_START,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  CLEAR_ERROR,
  GET_STORED_RESERVATION,

  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,

  SAVE_RESERVATION_START,
  SAVE_RESERVATION_SUCCESS,
  SAVE_RESERVATION_FAIL,

  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,

  CLEAR_RESERVATION,

  LIST_INSURANCES_START,
  LIST_INSURANCES_SUCCESS,
  LIST_INSURANCES_FAIL,

  GET_INSURANCE_START,
  GET_INSURANCE_SUCCESS,
  GET_INSURANCE_FAIL,

  CLEAR_INSURANCE,

  PRICEQUOTE_INSURANCE_START,
  PRICEQUOTE_INSURANCE_SUCCESS,
  PRICEQUOTE_INSURANCE_FAIL,
  CLEAR_PRICEQUOTE,

  SAVE_INSURANCE_START,
  SAVE_INSURANCE_SUCCESS,
  SAVE_INSURANCE_FAIL,

  AMEND_INSURANCE_START,
  AMEND_INSURANCE_SUCCESS,
  AMEND_INSURANCE_FAIL,

  CANCEL_INSURANCE_START,
  CANCEL_INSURANCE_SUCCESS,
  CANCEL_INSURANCE_FAIL,

  GET_STATIONS_START,
  GET_STATIONS_SUCCESS,
  GET_STATIONS_FAIL,

  CUSTOMER_RESERVATION_GET_START,
  CUSTOMER_RESERVATION_GET_SUCCESS,
  CUSTOMER_RESERVATION_GET_FAIL,

  CUSTOMER_RESERVATION_SAVE_START,
  CUSTOMER_RESERVATION_SAVE_SUCCESS,
  CUSTOMER_RESERVATION_SAVE_FAIL,

  CUSTOMER_RESERVATION_CLEAR,
}
