import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import {
  IExpressCheckoutState,
  IExpressCheckoutAction
} from "../../interfaces";

const initialState: IExpressCheckoutState = {
  expressCheckout: null,
  loading: false,
  error: null
};

const saveReservationStart = (
  state: IExpressCheckoutState,
  action: IExpressCheckoutAction
): IExpressCheckoutState => {
  return updateObject(state, { error: null, loading: true });
};

const saveReservationSuccess = (
  state: IExpressCheckoutState,
  action: IExpressCheckoutAction
): IExpressCheckoutState => {
  return updateObject(state, {
    status: action.status,
    error: null,
    loading: false
  });
};

const saveReservationFail = (
  state: IExpressCheckoutState,
  action: IExpressCheckoutAction
): IExpressCheckoutState => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const reducer = (
  state: IExpressCheckoutState = initialState,
  action: IExpressCheckoutAction
): IExpressCheckoutState => {
  switch (action.type) {
    case ActionTypes.SAVE_RESERVATION_START:
      return saveReservationStart(state, action);
    case ActionTypes.SAVE_RESERVATION_SUCCESS:
      return saveReservationSuccess(state, action);
    case ActionTypes.SAVE_RESERVATION_FAIL:
      return saveReservationFail(state, action);
    default:
      return state;
  }
};

export default reducer;
