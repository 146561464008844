import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IAuthState, IAuthAction } from "../../interfaces";

const initialState: IAuthState = {
  email: null,
  reservation: null,
  loading: false,
  error: null,
  redirect: null,
  authUser: null,
  success: false,
};

const storeAuthRedirect = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, { redirect: action.redirect });
};

const authStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, { error: null, loading: true, reservation: null });
};

const authSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    email: action.email,
    reservation: action.reservation,
    error: null,
    loading: false,
  });
};

const authFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const logoutStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: null,
    loading: true,
    reservation: null,
    authUser: null,
  });
};

const logoutSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    reservation: null,
    error: null,
    loading: false,
    email: null,
  });
};

const logoutFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearError = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: null,
  });
};

const getStoredReservation = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    reservation: action.reservation,
    loading: false,
    error: null,
  });
};

const loginStart = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: null,
    loading: true,
    reservation: null,
    success: false,
  });
};

const loginSuccess = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    email: action.email,
    authUser: action.user,
    error: null,
    loading: false,
    success: true,
  });
};

const loginFail = (state: IAuthState, action: IAuthAction): IAuthState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
    success: true,
  });
};

const clearReservation = (
  state: IAuthState,
  action: IAuthAction
): IAuthState => {
  return updateObject(state, {
    error: null,
    loading: false,
    success: false,
    reservation: null,
  });
};

const reducer = (
  state: IAuthState = initialState,
  action: IAuthAction
): IAuthState => {
  switch (action.type) {
    case ActionTypes.AUTH_START:
      return authStart(state, action);
    case ActionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case ActionTypes.AUTH_FAIL:
      return authFail(state, action);
    case ActionTypes.LOGOUT_START:
      return logoutStart(state, action);
    case ActionTypes.LOGOUT_SUCCESS:
      return logoutSuccess(state, action);
    case ActionTypes.LOGOUT_FAIL:
      return logoutFail(state, action);
    case ActionTypes.CLEAR_ERROR:
      return clearError(state, action);
    case ActionTypes.STORE_AUTH_REDIRECT:
      return storeAuthRedirect(state, action);
    case ActionTypes.GET_STORED_RESERVATION:
      return getStoredReservation(state, action);
    case ActionTypes.LOGIN_START:
      return loginStart(state, action);
    case ActionTypes.LOGIN_SUCCESS:
      return loginSuccess(state, action);
    case ActionTypes.LOGIN_FAIL:
      return loginFail(state, action);
    case ActionTypes.CLEAR_RESERVATION:
      return clearReservation(state, action);
    default:
      return state;
  }
};

export default reducer;
