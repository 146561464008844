import { ETranslation } from './../translations/translation-keys';
import { IOption } from '../components/UI/Input/Input';

export const placeOptions: IOption[] =  [
    { value: "", labelTranslation: ETranslation.PLACE_Default },
    { value: "AF", labelTranslation: ETranslation.PLACE_Afghanistan },
    { value: "AX", labelTranslation: ETranslation.PLACE_Åland_Islands },
    { value: "AL", labelTranslation: ETranslation.PLACE_Albania },
    { value: "DZ", labelTranslation: ETranslation.PLACE_Algeria },
    { value: "AS", labelTranslation: ETranslation.PLACE_American_Samoa },
    { value: "AD", labelTranslation: ETranslation.PLACE_Andorra },
    { value: "AO", labelTranslation: ETranslation.PLACE_Angola },
    { value: "AI", labelTranslation: ETranslation.PLACE_Anguilla },
    { value: "AQ", labelTranslation: ETranslation.PLACE_Antarctica },
    { value: "AG", labelTranslation: ETranslation.PLACE_Antigua_and_Barbuda },
    { value: "AR", labelTranslation: ETranslation.PLACE_Argentina },
    { value: "AM", labelTranslation: ETranslation.PLACE_Armenia },
    { value: "AW", labelTranslation: ETranslation.PLACE_Aruba },
    { value: "AU", labelTranslation: ETranslation.PLACE_Australia },
    { value: "AT", labelTranslation: ETranslation.PLACE_Austria },
    { value: "AZ", labelTranslation: ETranslation.PLACE_Azerbaijan },
    { value: "BS", labelTranslation: ETranslation.PLACE_Bahamas },
    { value: "BH", labelTranslation: ETranslation.PLACE_Bahrain },
    { value: "BD", labelTranslation: ETranslation.PLACE_Bangladesh },
    { value: "BB", labelTranslation: ETranslation.PLACE_Barbados },
    { value: "BY", labelTranslation: ETranslation.PLACE_Belarus },
    { value: "BE", labelTranslation: ETranslation.PLACE_Belgium },
    { value: "BZ", labelTranslation: ETranslation.PLACE_Belize },
    { value: "BJ", labelTranslation: ETranslation.PLACE_Benin },
    { value: "BM", labelTranslation: ETranslation.PLACE_Bermuda },
    { value: "BT", labelTranslation: ETranslation.PLACE_Bhutan },
    { value: "BO", labelTranslation: ETranslation.PLACE_Bolivia_Plurinational_State_of },
    { value: "BQ", labelTranslation: ETranslation.PLACE_Bonaire_Sint_Eustatius_and_Saba },
    { value: "BA", labelTranslation: ETranslation.PLACE_Bosnia_and_Herzegovina },
    { value: "BW", labelTranslation: ETranslation.PLACE_Botswana },
    { value: "BV", labelTranslation: ETranslation.PLACE_Bouvet_Island },
    { value: "BR", labelTranslation: ETranslation.PLACE_Brazil },
    { value: "IO", labelTranslation: ETranslation.PLACE_British_Indian_Ocean_Territory },
    { value: "BN", labelTranslation: ETranslation.PLACE_Brunei_Darussalam },
    { value: "BG", labelTranslation: ETranslation.PLACE_Bulgaria },
    { value: "BF", labelTranslation: ETranslation.PLACE_Burkina_Faso },
    { value: "BI", labelTranslation: ETranslation.PLACE_Burundi },
    { value: "KH", labelTranslation: ETranslation.PLACE_Cambodia },
    { value: "CM", labelTranslation: ETranslation.PLACE_Cameroon },
    { value: "CA", labelTranslation: ETranslation.PLACE_Canada },
    { value: "CV", labelTranslation: ETranslation.PLACE_Cape_Verde },
    { value: "KY", labelTranslation: ETranslation.PLACE_Cayman_Islands },
    { value: "CF", labelTranslation: ETranslation.PLACE_Central_African_Republic },
    { value: "TD", labelTranslation: ETranslation.PLACE_Chad },
    { value: "CL", labelTranslation: ETranslation.PLACE_Chile },
    { value: "CN", labelTranslation: ETranslation.PLACE_China },
    { value: "CX", labelTranslation: ETranslation.PLACE_Christmas_Island },
    { value: "CC", labelTranslation: ETranslation.PLACE_Cocos_Keeling_Islands },
    { value: "CO", labelTranslation: ETranslation.PLACE_Colombia },
    { value: "KM", labelTranslation: ETranslation.PLACE_Comoros },
    { value: "CG", labelTranslation: ETranslation.PLACE_Congo },
    { value: "CD", labelTranslation: ETranslation.PLACE_Congo_the_Democratic_Republic_of_the },
    { value: "CK", labelTranslation: ETranslation.PLACE_Cook_Islands },
    { value: "CR", labelTranslation: ETranslation.PLACE_Costa_Rica },
    { value: "CI", labelTranslation: ETranslation.PLACE_Côte_dIvoire },
    { value: "HR", labelTranslation: ETranslation.PLACE_Croatia },
    { value: "CU", labelTranslation: ETranslation.PLACE_Cuba },
    { value: "CW", labelTranslation: ETranslation.PLACE_Curaçao },
    { value: "CY", labelTranslation: ETranslation.PLACE_Cyprus },
    { value: "CZ", labelTranslation: ETranslation.PLACE_Czech_Republic },
    { value: "DK", labelTranslation: ETranslation.PLACE_Denmark },
    { value: "DJ", labelTranslation: ETranslation.PLACE_Djibouti },
    { value: "DM", labelTranslation: ETranslation.PLACE_Dominica },
    { value: "DO", labelTranslation: ETranslation.PLACE_Dominican_Republic },
    { value: "EC", labelTranslation: ETranslation.PLACE_Ecuador },
    { value: "EG", labelTranslation: ETranslation.PLACE_Egypt },
    { value: "SV", labelTranslation: ETranslation.PLACE_El_Salvador },
    { value: "GQ", labelTranslation: ETranslation.PLACE_Equatorial_Guinea },
    { value: "ER", labelTranslation: ETranslation.PLACE_Eritrea },
    { value: "EE", labelTranslation: ETranslation.PLACE_Estonia },
    { value: "ET", labelTranslation: ETranslation.PLACE_Ethiopia },
    { value: "FK", labelTranslation: ETranslation.PLACE_Falkland_Islands_Malvinas },
    { value: "FO", labelTranslation: ETranslation.PLACE_Faroe_Islands },
    { value: "FJ", labelTranslation: ETranslation.PLACE_Fiji },
    { value: "FI", labelTranslation: ETranslation.PLACE_Finland },
    { value: "FR", labelTranslation: ETranslation.PLACE_France },
    { value: "GF", labelTranslation: ETranslation.PLACE_French_Guiana },
    { value: "PF", labelTranslation: ETranslation.PLACE_French_Polynesia },
    { value: "TF", labelTranslation: ETranslation.PLACE_French_Southern_Territories },
    { value: "GA", labelTranslation: ETranslation.PLACE_Gabon },
    { value: "GM", labelTranslation: ETranslation.PLACE_Gambia },
    { value: "GE", labelTranslation: ETranslation.PLACE_Georgia },
    { value: "DE", labelTranslation: ETranslation.PLACE_Germany },
    { value: "GH", labelTranslation: ETranslation.PLACE_Ghana },
    { value: "GI", labelTranslation: ETranslation.PLACE_Gibraltar },
    { value: "GR", labelTranslation: ETranslation.PLACE_Greece },
    { value: "GL", labelTranslation: ETranslation.PLACE_Greenland },
    { value: "GD", labelTranslation: ETranslation.PLACE_Grenada },
    { value: "GP", labelTranslation: ETranslation.PLACE_Guadeloupe },
    { value: "GU", labelTranslation: ETranslation.PLACE_Guam },
    { value: "GT", labelTranslation: ETranslation.PLACE_Guatemala },
    { value: "GG", labelTranslation: ETranslation.PLACE_Guernsey },
    { value: "GN", labelTranslation: ETranslation.PLACE_Guinea },
    { value: "GW", labelTranslation: ETranslation.PLACE_Guinea_Bissau },
    { value: "GY", labelTranslation: ETranslation.PLACE_Guyana },
    { value: "HT", labelTranslation: ETranslation.PLACE_Haiti },
    { value: "HM", labelTranslation: ETranslation.PLACE_Heard_Island_and_McDonald_Islands },
    { value: "VA", labelTranslation: ETranslation.PLACE_Holy_See_Vatican_City_State },
    { value: "HN", labelTranslation: ETranslation.PLACE_Honduras },
    { value: "HK", labelTranslation: ETranslation.PLACE_Hong_Kong },
    { value: "HU", labelTranslation: ETranslation.PLACE_Hungary },
    { value: "IS", labelTranslation: ETranslation.PLACE_Iceland },
    { value: "IN", labelTranslation: ETranslation.PLACE_India },
    { value: "ID", labelTranslation: ETranslation.PLACE_Indonesia },
    { value: "IR", labelTranslation: ETranslation.PLACE_Iran_Islamic_Republic_of },
    { value: "IQ", labelTranslation: ETranslation.PLACE_Iraq },
    { value: "IE", labelTranslation: ETranslation.PLACE_Ireland },
    { value: "IM", labelTranslation: ETranslation.PLACE_Isle_of_Man },
    { value: "IL", labelTranslation: ETranslation.PLACE_Israel },
    { value: "IT", labelTranslation: ETranslation.PLACE_Italy },
    { value: "JM", labelTranslation: ETranslation.PLACE_Jamaica },
    { value: "JP", labelTranslation: ETranslation.PLACE_Japan },
    { value: "JE", labelTranslation: ETranslation.PLACE_Jersey },
    { value: "JO", labelTranslation: ETranslation.PLACE_Jordan },
    { value: "KZ", labelTranslation: ETranslation.PLACE_Kazakhstan },
    { value: "KE", labelTranslation: ETranslation.PLACE_Kenya },
    { value: "KI", labelTranslation: ETranslation.PLACE_Kiribati },
    { value: "KP", labelTranslation: ETranslation.PLACE_Korea_Democratic_Peoples_Republic_of },
    { value: "KR", labelTranslation: ETranslation.PLACE_Korea_Republic_of },
    { value: "KW", labelTranslation: ETranslation.PLACE_Kuwait },
    { value: "KG", labelTranslation: ETranslation.PLACE_Kyrgyzstan },
    { value: "LA", labelTranslation: ETranslation.PLACE_Lao_Peoples_Democratic_Republic },
    { value: "LV", labelTranslation: ETranslation.PLACE_Latvia },
    { value: "LB", labelTranslation: ETranslation.PLACE_Lebanon },
    { value: "LS", labelTranslation: ETranslation.PLACE_Lesotho },
    { value: "LR", labelTranslation: ETranslation.PLACE_Liberia },
    { value: "LY", labelTranslation: ETranslation.PLACE_Libya },
    { value: "LI", labelTranslation: ETranslation.PLACE_Liechtenstein },
    { value: "LT", labelTranslation: ETranslation.PLACE_Lithuania },
    { value: "LU", labelTranslation: ETranslation.PLACE_Luxembourg },
    { value: "MO", labelTranslation: ETranslation.PLACE_Macao },
    { value: "MK", labelTranslation: ETranslation.PLACE_Macedonia_the_former_Yugoslav_Republic_of },
    { value: "MG", labelTranslation: ETranslation.PLACE_Madagascar },
    { value: "MW", labelTranslation: ETranslation.PLACE_Malawi },
    { value: "MY", labelTranslation: ETranslation.PLACE_Malaysia },
    { value: "MV", labelTranslation: ETranslation.PLACE_Maldives },
    { value: "ML", labelTranslation: ETranslation.PLACE_Mali },
    { value: "MT", labelTranslation: ETranslation.PLACE_Malta },
    { value: "MH", labelTranslation: ETranslation.PLACE_Marshall_Islands },
    { value: "MQ", labelTranslation: ETranslation.PLACE_Martinique },
    { value: "MR", labelTranslation: ETranslation.PLACE_Mauritania },
    { value: "MU", labelTranslation: ETranslation.PLACE_Mauritius },
    { value: "YT", labelTranslation: ETranslation.PLACE_Mayotte },
    { value: "MX", labelTranslation: ETranslation.PLACE_Mexico },
    { value: "FM", labelTranslation: ETranslation.PLACE_Micronesia_Federated_States_of },
    { value: "MD", labelTranslation: ETranslation.PLACE_Moldova_Republic_of },
    { value: "MC", labelTranslation: ETranslation.PLACE_Monaco },
    { value: "MN", labelTranslation: ETranslation.PLACE_Mongolia },
    { value: "ME", labelTranslation: ETranslation.PLACE_Montenegro },
    { value: "MS", labelTranslation: ETranslation.PLACE_Montserrat },
    { value: "MA", labelTranslation: ETranslation.PLACE_Morocco },
    { value: "MZ", labelTranslation: ETranslation.PLACE_Mozambique },
    { value: "MM", labelTranslation: ETranslation.PLACE_Myanmar },
    { value: "NA", labelTranslation: ETranslation.PLACE_Namibia },
    { value: "NR", labelTranslation: ETranslation.PLACE_Nauru },
    { value: "NP", labelTranslation: ETranslation.PLACE_Nepal },
    { value: "NL", labelTranslation: ETranslation.PLACE_Netherlands },
    { value: "NC", labelTranslation: ETranslation.PLACE_New_Caledonia },
    { value: "NZ", labelTranslation: ETranslation.PLACE_New_Zealand },
    { value: "NI", labelTranslation: ETranslation.PLACE_Nicaragua },
    { value: "NE", labelTranslation: ETranslation.PLACE_Niger },
    { value: "NG", labelTranslation: ETranslation.PLACE_Nigeria },
    { value: "NU", labelTranslation: ETranslation.PLACE_Niue },
    { value: "NF", labelTranslation: ETranslation.PLACE_Norfolk_Island },
    { value: "MP", labelTranslation: ETranslation.PLACE_Northern_Mariana_Islands },
    { value: "NO", labelTranslation: ETranslation.PLACE_Norway },
    { value: "OM", labelTranslation: ETranslation.PLACE_Oman },
    { value: "PK", labelTranslation: ETranslation.PLACE_Pakistan },
    { value: "PW", labelTranslation: ETranslation.PLACE_Palau },
    { value: "PS", labelTranslation: ETranslation.PLACE_Palestinian_Territory_Occupied },
    { value: "PA", labelTranslation: ETranslation.PLACE_Panama },
    { value: "PG", labelTranslation: ETranslation.PLACE_Papua_New_Guinea },
    { value: "PY", labelTranslation: ETranslation.PLACE_Paraguay },
    { value: "PE", labelTranslation: ETranslation.PLACE_Peru },
    { value: "PH", labelTranslation: ETranslation.PLACE_Philippines },
    { value: "PN", labelTranslation: ETranslation.PLACE_Pitcairn },
    { value: "PL", labelTranslation: ETranslation.PLACE_Poland },
    { value: "PT", labelTranslation: ETranslation.PLACE_Portugal },
    { value: "PR", labelTranslation: ETranslation.PLACE_Puerto_Rico },
    { value: "QA", labelTranslation: ETranslation.PLACE_Qatar },
    { value: "RE", labelTranslation: ETranslation.PLACE_Réunion },
    { value: "RO", labelTranslation: ETranslation.PLACE_Romania },
    { value: "RU", labelTranslation: ETranslation.PLACE_Russian_Federation },
    { value: "RW", labelTranslation: ETranslation.PLACE_Rwanda },
    { value: "BL", labelTranslation: ETranslation.PLACE_Saint_Barthélemy },
    { value: "SH", labelTranslation: ETranslation.PLACE_Saint_Helena_Ascension_and_Tristan_da_Cunha },
    { value: "KN", labelTranslation: ETranslation.PLACE_Saint_Kitts_and_Nevis },
    { value: "LC", labelTranslation: ETranslation.PLACE_Saint_Lucia },
    { value: "MF", labelTranslation: ETranslation.PLACE_Saint_Martin_French_part },
    { value: "PM", labelTranslation: ETranslation.PLACE_Saint_Pierre_and_Miquelon },
    { value: "VC", labelTranslation: ETranslation.PLACE_Saint_Vincent_and_the_Grenadines },
    { value: "WS", labelTranslation: ETranslation.PLACE_Samoa },
    { value: "SM", labelTranslation: ETranslation.PLACE_San_Marino },
    { value: "ST", labelTranslation: ETranslation.PLACE_Sao_Tome_and_Principe },
    { value: "SA", labelTranslation: ETranslation.PLACE_Saudi_Arabia },
    { value: "SN", labelTranslation: ETranslation.PLACE_Senegal },
    { value: "RS", labelTranslation: ETranslation.PLACE_Serbia },
    { value: "SC", labelTranslation: ETranslation.PLACE_Seychelles },
    { value: "SL", labelTranslation: ETranslation.PLACE_Sierra_Leone },
    { value: "SG", labelTranslation: ETranslation.PLACE_Singapore },
    { value: "SX", labelTranslation: ETranslation.PLACE_Sint_Maarten_Dutch_part },
    { value: "SK", labelTranslation: ETranslation.PLACE_Slovakia },
    { value: "SI", labelTranslation: ETranslation.PLACE_Slovenia },
    { value: "SB", labelTranslation: ETranslation.PLACE_Solomon_Islands },
    { value: "SO", labelTranslation: ETranslation.PLACE_Somalia },
    { value: "ZA", labelTranslation: ETranslation.PLACE_South_Africa },
    { value: "GS", labelTranslation: ETranslation.PLACE_South_Georgia_and_the_South_Sandwich_Islands },
    { value: "SS", labelTranslation: ETranslation.PLACE_South_Sudan },
    { value: "ES", labelTranslation: ETranslation.PLACE_Spain },
    { value: "LK", labelTranslation: ETranslation.PLACE_Sri_Lanka },
    { value: "SD", labelTranslation: ETranslation.PLACE_Sudan },
    { value: "SR", labelTranslation: ETranslation.PLACE_Suriname },
    { value: "SJ", labelTranslation: ETranslation.PLACE_Svalbard_and_Jan_Mayen },
    { value: "SZ", labelTranslation: ETranslation.PLACE_Swaziland },
    { value: "SE", labelTranslation: ETranslation.PLACE_Sweden },
    { value: "CH", labelTranslation: ETranslation.PLACE_Switzerland },
    { value: "SY", labelTranslation: ETranslation.PLACE_Syrian_Arab_Republic },
    { value: "TW", labelTranslation: ETranslation.PLACE_Taiwan_Province_of_China },
    { value: "TJ", labelTranslation: ETranslation.PLACE_Tajikistan },
    { value: "TZ", labelTranslation: ETranslation.PLACE_Tanzania_United_Republic_of },
    { value: "TH", labelTranslation: ETranslation.PLACE_Thailand },
    { value: "TL", labelTranslation: ETranslation.PLACE_Timor_Leste },
    { value: "TG", labelTranslation: ETranslation.PLACE_Togo },
    { value: "TK", labelTranslation: ETranslation.PLACE_Tokelau },
    { value: "TO", labelTranslation: ETranslation.PLACE_Tonga },
    { value: "TT", labelTranslation: ETranslation.PLACE_Trinidad_and_Tobago },
    { value: "TN", labelTranslation: ETranslation.PLACE_Tunisia },
    { value: "TR", labelTranslation: ETranslation.PLACE_Turkey },
    { value: "TM", labelTranslation: ETranslation.PLACE_Turkmenistan },
    { value: "TC", labelTranslation: ETranslation.PLACE_Turks_and_Caicos_Islands },
    { value: "TV", labelTranslation: ETranslation.PLACE_Tuvalu },
    { value: "UG", labelTranslation: ETranslation.PLACE_Uganda },
    { value: "UA", labelTranslation: ETranslation.PLACE_Ukraine },
    { value: "AE", labelTranslation: ETranslation.PLACE_United_Arab_Emirates },
    { value: "GB", labelTranslation: ETranslation.PLACE_United_Kingdom },
    { value: "US", labelTranslation: ETranslation.PLACE_United_States },
    { value: "UM", labelTranslation: ETranslation.PLACE_United_States_Minor_Outlying_Islands },
    { value: "UY", labelTranslation: ETranslation.PLACE_Uruguay },
    { value: "UZ", labelTranslation: ETranslation.PLACE_Uzbekistan },
    { value: "VU", labelTranslation: ETranslation.PLACE_Vanuatu },
    { value: "VE", labelTranslation: ETranslation.PLACE_Venezuela_Bolivarian_Republic_of },
    { value: "VN", labelTranslation: ETranslation.PLACE_Viet_Nam },
    { value: "VG", labelTranslation: ETranslation.PLACE_Virgin_Islands_British },
    { value: "VI", labelTranslation: ETranslation.PLACE_Virgin_Islands_US },
    { value: "WF", labelTranslation: ETranslation.PLACE_Wallis_and_Futuna },
    { value: "EH", labelTranslation: ETranslation.PLACE_Western_Sahara },
    { value: "YE", labelTranslation: ETranslation.PLACE_Yemen },
    { value: "ZM", labelTranslation: ETranslation.PLACE_Zambia },
    { value: "ZW", labelTranslation: ETranslation.PLACE_Zimbabwe },
];