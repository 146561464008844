import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IStation, IStationAction } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";

const getStationsStart = (): IStationAction => {
  return {
    type: ActionTypes.GET_STATIONS_START,
  };
};

const getStationsSuccess = (stations: IStation[]): IStationAction => {
  return {
    type: ActionTypes.GET_STATIONS_SUCCESS,
    stations,
  };
};

const getStationsFail = (error: string): IStationAction => {
  return {
    type: ActionTypes.GET_STATIONS_FAIL,
    error,
  };
};

export const getStations = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getStationsStart());
    try {
      const res = await axios.get(`/json/stations`);
// if run from VS Code      const res = await axios.get(`http://app.touringcars.eu:8080/json/stations`);
      //console.log("station result: ", res);
      dispatch(getStationsSuccess(res.data));
    } catch ({ response }) {
      dispatch(getStationsFail((response as AxiosResponse).data));
    }
  };
};
