import { ETranslation } from "./translation-keys";

const commonTranslations = {
  [ETranslation.COMMON_SAVE]: "Save",
  [ETranslation.COMMON_YES]: "Yes",
  [ETranslation.COMMON_NO]: "No"
};

const authTranslations = {
  [ETranslation.LOG_IN]: "Log In",
  [ETranslation.EMAIL]: "Email address",
  [ETranslation.IRN]: "Booking IRN Number",
  [ETranslation.PASSWORD]: "Password",
};

const insurancesTranslation = {
  [ETranslation.INSURANCES_TITLE]: "Insurances",
  [ETranslation.INSURANCES_STATUS]: "Status",
  [ETranslation.INSURANCES_CREATED_DATE]: "Created",
  [ETranslation.INSURANCES_START_DATE]: "Start date",
  [ETranslation.INSURANCES_END_DATE]: "End date",
  [ETranslation.INSURANCES_STATION]: "Station",
  [ETranslation.INSURANCES_IRN]: "IRN",
  [ETranslation.INSURANCES_CUSTOMER_FIRST_NAME]: "First name",
  [ETranslation.INSURANCES_CUSTOMER_LAST_NAME]: "Last name",
  [ETranslation.INSURANCES_CUSTOMER_NAME]: "Name",
  [ETranslation.INSURANCES_CUSTOMER_EMAIL]: "Email",
  [ETranslation.INSURANCES_CUSTOMER_DETAILS_TITLE]: "Customer details",
  [ETranslation.NO_INSURANCES]: "No insurances.",
};

const insuranceTranslation = {
  [ETranslation.INSURANCE_SAVE_BUTTON]: "Save",
  [ETranslation.INSURANCE_RETURN_BUTTON]: "Return",
  [ETranslation.INSURANCE_CANCEL_BUTTON]: "Cancel insurance",
  [ETranslation.INSURANCE_NEW_TITLE]: "New Insurance",
  [ETranslation.INSURANCE]: "Insurance",
  [ETranslation.INSURANCE_START_DATE]: "Start date",
  [ETranslation.INSURANCE_END_DATE]: "End date",
  [ETranslation.INSURANCE_PRICE_LABEL]: "Insurance price",
  [ETranslation.INSURANCE_TAX_LABEL]: "incl. tax",
  [ETranslation.INSURANCE_ISOCURRENCY]: "ISO currency (e.g. EUR, NOK)",
  [ETranslation.INSURANCE_BOOKING_REF]: "Booking reference",
  [ETranslation.INSURANCE_BOOKING_IRN]: "Booking IRN",
  [ETranslation.INSURANCE_PICKUP_COUNTRYISO]: "Country of residence",
  [ETranslation.INSURANCE_PICKUP_LOCATION]: "Pickup location",
  [ETranslation.INSURANCE_FIRSTNAME]: "Firstname",
  [ETranslation.INSURANCE_LASTNAME]: "Lastname",
  [ETranslation.INSURANCE_EMAIL]: "Email",
  [ETranslation.INSURANCE_ADDRESS]: "Address",
  [ETranslation.INSURANCE_CITY]: "City",
  [ETranslation.INSURANCE_ZIP]: "Zip",
  [ETranslation.INSURANCE_PHONE]: "Phone",
  [ETranslation.INSURANCE_AUDIT_TRAIL]: "Audit trail",
  [ETranslation.INSURANCE_DEBUG]: "Debug",
  [ETranslation.INSURANCE_PRICE_QUOTE]: "Price quote",
  [ETranslation.INSURANCE_RETURN_LOCATION]: "Return location",
  [ETranslation.INSURANCE_AUDIT_TRAIL_CHANGE_LOG]: "Audit trail change log",
  [ETranslation.INSURANCE_MODAL_CANCEL_TITLE]: "Cancel insurance",
  [ETranslation.INSURANCE_MODAL_CANCEL_BODY]: "Do you want to cancel the insurance? Insurance cancel confirmation will be sent to customer's email.",
  [ETranslation.INSURANCE_MODAL_AMEND_TITLE]: "Save changes",
  [ETranslation.INSURANCE_MODAL_AMEND_BODY]: "Do you want to save changes to the insurance?",
  [ETranslation.INSURANCE_MODAL_SAVE_TITLE]: "Save new insurance",
  [ETranslation.INSURANCE_MODAL_SAVE_BODY]: "Do you want to create new insurance? Insurance documents will be sent to customer's email.",
};

const expressCheckoutTranslations = {
  [ETranslation.TITLE_RESERVATION_INFORMATION]: "Reservation Information",
  [ETranslation.TITLE_PERSONAL_INFORMATION]: "Personal Information",
  [ETranslation.TITLE_LICENSE_INFORMATION]: "Driver's License",
  [ETranslation.TITLE_IDENTITY_INFORMATION]: "Passport / Identification",
  [ETranslation.TITLE_PICKUP_INFORMATION]: "Pick Up Details",
  [ETranslation.TITLE_RETURN_INFORMATION]: "Return Details",
  [ETranslation.TITLE_REFERENCE_NUMBER]: "Reference number",
  [ETranslation.TITLE_IRN]: "IRN",
  [ETranslation.TITLE_STATUS]: "Status",
  
  [ETranslation.CNC]: "Cancelled",
  [ETranslation.RES]: "Ok",
  [ETranslation.REQ]: "On request",

  

  [ETranslation.AGENT]: "Agent",
  [ETranslation.REFNO]: "Your Reference Number",
  [ETranslation.IRN]: "IRN number",
  [ETranslation.DOB]: "Date of Birth",
  [ETranslation.POB]: "Place of Birth",
  [ETranslation.LICNO]: "License Number",
  [ETranslation.LICPLACE]: "License Issue Place",
  [ETranslation.LICISSUEDATE]: "License Issue Date",
  [ETranslation.LICEXPDATE]: "License Expiration Date",
  [ETranslation.IDNO]: "Identity Number",
  [ETranslation.IDPLACE]: "Identity Issue Place",
  [ETranslation.IDISSUEDATE]: "Identity Issue Date",
  [ETranslation.IDEXPDATE]: "Identity Expiration Date"
};

const placeTranslations = {
  [ETranslation.PLACE_Default]: "Select place",
  [ETranslation.PLACE_Afghanistan]: "Afghanistan",
  [ETranslation.PLACE_Åland_Islands]: "Åland Islands",
  [ETranslation.PLACE_Albania]: "Albania",
  [ETranslation.PLACE_Algeria]: "Algeria",
  [ETranslation.PLACE_American_Samoa]: "American Samoa",
  [ETranslation.PLACE_Andorra]: "Andorra",
  [ETranslation.PLACE_Angola]: "Angola",
  [ETranslation.PLACE_Anguilla]: "Anguilla",
  [ETranslation.PLACE_Antarctica]: "Antarctica",
  [ETranslation.PLACE_Antigua_and_Barbuda]: "Antigua and Barbuda",
  [ETranslation.PLACE_Argentina]: "Argentina",
  [ETranslation.PLACE_Armenia]: "Armenia",
  [ETranslation.PLACE_Aruba]: "Aruba",
  [ETranslation.PLACE_Australia]: "Australia",
  [ETranslation.PLACE_Austria]: "Austria",
  [ETranslation.PLACE_Azerbaijan]: "Azerbaijan",
  [ETranslation.PLACE_Bahamas]: "Bahamas",
  [ETranslation.PLACE_Bahrain]: "Bahrain",
  [ETranslation.PLACE_Bangladesh]: "Bangladesh",
  [ETranslation.PLACE_Barbados]: "Barbados",
  [ETranslation.PLACE_Belarus]: "Belarus",
  [ETranslation.PLACE_Belgium]: "Belgium",
  [ETranslation.PLACE_Belize]: "Belize",
  [ETranslation.PLACE_Benin]: "Benin",
  [ETranslation.PLACE_Bermuda]: "Bermuda",
  [ETranslation.PLACE_Bhutan]: "Bhutan",
  [ETranslation.PLACE_Bolivia_Plurinational_State_of]: "Bolivia, Plurinational State of",
  [ETranslation.PLACE_Bonaire_Sint_Eustatius_and_Saba]: "Bonaire, Sint Eustatius and Saba",
  [ETranslation.PLACE_Bosnia_and_Herzegovina]: "Bosnia and Herzegovina",
  [ETranslation.PLACE_Botswana]: "Botswana",
  [ETranslation.PLACE_Bouvet_Island]: "Bouvet Island",
  [ETranslation.PLACE_Brazil]: "Brazil",
  [ETranslation.PLACE_British_Indian_Ocean_Territory]: "British Indian Ocean Territory",
  [ETranslation.PLACE_Brunei_Darussalam]: "Brunei Darussalam",
  [ETranslation.PLACE_Bulgaria]: "Bulgaria",
  [ETranslation.PLACE_Burkina_Faso]: "Burkina Faso",
  [ETranslation.PLACE_Burundi]: "Burundi",
  [ETranslation.PLACE_Cambodia]: "Cambodia",
  [ETranslation.PLACE_Cameroon]: "Cameroon",
  [ETranslation.PLACE_Canada]: "Canada",
  [ETranslation.PLACE_Cape_Verde]: "Cape Verde",
  [ETranslation.PLACE_Cayman_Islands]: "Cayman Islands",
  [ETranslation.PLACE_Central_African_Republic]: "Central African Republic",
  [ETranslation.PLACE_Chad]: "Chad",
  [ETranslation.PLACE_Chile]: "Chile",
  [ETranslation.PLACE_China]: "China",
  [ETranslation.PLACE_Christmas_Island]: "Christmas Island",
  [ETranslation.PLACE_Cocos_Keeling_Islands]: "Cocos (Keeling) Islands",
  [ETranslation.PLACE_Colombia]: "Colombia",
  [ETranslation.PLACE_Comoros]: "Comoros",
  [ETranslation.PLACE_Congo]: "Congo",
  [ETranslation.PLACE_Congo_the_Democratic_Republic_of_the]: "Congo, the Democratic Republic of the",
  [ETranslation.PLACE_Cook_Islands]: "Cook Islands",
  [ETranslation.PLACE_Costa_Rica]: "Costa Rica",
  [ETranslation.PLACE_Côte_dIvoire]: "Côte d'Ivoire",
  [ETranslation.PLACE_Croatia]: "Croatia",
  [ETranslation.PLACE_Cuba]: "Cuba",
  [ETranslation.PLACE_Curaçao]: "Curaçao",
  [ETranslation.PLACE_Cyprus]: "Cyprus",
  [ETranslation.PLACE_Czech_Republic]: "Czech Republic",
  [ETranslation.PLACE_Denmark]: "Denmark",
  [ETranslation.PLACE_Djibouti]: "Djibouti",
  [ETranslation.PLACE_Dominica]: "Dominica",
  [ETranslation.PLACE_Dominican_Republic]: "Dominican Republic",
  [ETranslation.PLACE_Ecuador]: "Ecuador",
  [ETranslation.PLACE_Egypt]: "Egypt",
  [ETranslation.PLACE_El_Salvador]: "El Salvador",
  [ETranslation.PLACE_Equatorial_Guinea]: "Equatorial Guinea",
  [ETranslation.PLACE_Eritrea]: "Eritrea",
  [ETranslation.PLACE_Estonia]: "Estonia",
  [ETranslation.PLACE_Ethiopia]: "Ethiopia",
  [ETranslation.PLACE_Falkland_Islands_Malvinas]: "Falkland Islands (Malvinas)",
  [ETranslation.PLACE_Faroe_Islands]: "Faroe Islands",
  [ETranslation.PLACE_Fiji]: "Fiji",
  [ETranslation.PLACE_Finland]: "Finland",
  [ETranslation.PLACE_France]: "France",
  [ETranslation.PLACE_French_Guiana]: "French Guiana",
  [ETranslation.PLACE_French_Polynesia]: "French Polynesia",
  [ETranslation.PLACE_French_Southern_Territories]: "French Southern Territories",
  [ETranslation.PLACE_Gabon]: "Gabon",
  [ETranslation.PLACE_Gambia]: "Gambia",
  [ETranslation.PLACE_Georgia]: "Georgia",
  [ETranslation.PLACE_Germany]: "Germany",
  [ETranslation.PLACE_Ghana]: "Ghana",
  [ETranslation.PLACE_Gibraltar]: "Gibraltar",
  [ETranslation.PLACE_Greece]: "Greece",
  [ETranslation.PLACE_Greenland]: "Greenland",
  [ETranslation.PLACE_Grenada]: "Grenada",
  [ETranslation.PLACE_Guadeloupe]: "Guadeloupe",
  [ETranslation.PLACE_Guam]: "Guam",
  [ETranslation.PLACE_Guatemala]: "Guatemala",
  [ETranslation.PLACE_Guernsey]: "Guernsey",
  [ETranslation.PLACE_Guinea]: "Guinea",
  [ETranslation.PLACE_Guinea_Bissau]: "Guinea-Bissau",
  [ETranslation.PLACE_Guyana]: "Guyana",
  [ETranslation.PLACE_Haiti]: "Haiti",
  [ETranslation.PLACE_Heard_Island_and_McDonald_Islands]: "Heard Island and McDonald Islands",
  [ETranslation.PLACE_Holy_See_Vatican_City_State]: "Holy See (Vatican City State)",
  [ETranslation.PLACE_Honduras]: "Honduras",
  [ETranslation.PLACE_Hong_Kong]: "Hong Kong",
  [ETranslation.PLACE_Hungary]: "Hungary",
  [ETranslation.PLACE_Iceland]: "Iceland",
  [ETranslation.PLACE_India]: "India",
  [ETranslation.PLACE_Indonesia]: "Indonesia",
  [ETranslation.PLACE_Iran_Islamic_Republic_of]: "Iran, Islamic Republic of",
  [ETranslation.PLACE_Iraq]: "Iraq",
  [ETranslation.PLACE_Ireland]: "Ireland",
  [ETranslation.PLACE_Isle_of_Man]: "Isle of Man",
  [ETranslation.PLACE_Israel]: "Israel",
  [ETranslation.PLACE_Italy]: "Italy",
  [ETranslation.PLACE_Jamaica]: "Jamaica",
  [ETranslation.PLACE_Japan]: "Japan",
  [ETranslation.PLACE_Jersey]: "Jersey",
  [ETranslation.PLACE_Jordan]: "Jordan",
  [ETranslation.PLACE_Kazakhstan]: "Kazakhstan",
  [ETranslation.PLACE_Kenya]: "Kenya",
  [ETranslation.PLACE_Kiribati]: "Kiribati",
  [ETranslation.PLACE_Korea_Democratic_Peoples_Republic_of]: "Korea, Democratic People's Republic of",
  [ETranslation.PLACE_Korea_Republic_of]: "Korea, Republic of",
  [ETranslation.PLACE_Kuwait]: "Kuwait",
  [ETranslation.PLACE_Kyrgyzstan]: "Kyrgyzstan",
  [ETranslation.PLACE_Lao_Peoples_Democratic_Republic]: "Lao People's Democratic Republic",
  [ETranslation.PLACE_Latvia]: "Latvia",
  [ETranslation.PLACE_Lebanon]: "Lebanon",
  [ETranslation.PLACE_Lesotho]: "Lesotho",
  [ETranslation.PLACE_Liberia]: "Liberia",
  [ETranslation.PLACE_Libya]: "Libya",
  [ETranslation.PLACE_Liechtenstein]: "Liechtenstein",
  [ETranslation.PLACE_Lithuania]: "Lithuania",
  [ETranslation.PLACE_Luxembourg]: "Luxembourg",
  [ETranslation.PLACE_Macao]: "Macao",
  [ETranslation.PLACE_Macedonia_the_former_Yugoslav_Republic_of]: "Macedonia, the former Yugoslav Republic of",
  [ETranslation.PLACE_Madagascar]: "Madagascar",
  [ETranslation.PLACE_Malawi]: "Malawi",
  [ETranslation.PLACE_Malaysia]: "Malaysia",
  [ETranslation.PLACE_Maldives]: "Maldives",
  [ETranslation.PLACE_Mali]: "Mali",
  [ETranslation.PLACE_Malta]: "Malta",
  [ETranslation.PLACE_Marshall_Islands]: "Marshall Islands",
  [ETranslation.PLACE_Martinique]: "Martinique",
  [ETranslation.PLACE_Mauritania]: "Mauritania",
  [ETranslation.PLACE_Mauritius]: "Mauritius",
  [ETranslation.PLACE_Mayotte]: "Mayotte",
  [ETranslation.PLACE_Mexico]: "Mexico",
  [ETranslation.PLACE_Micronesia_Federated_States_of]: "Micronesia, Federated States of",
  [ETranslation.PLACE_Moldova_Republic_of]: "Moldova, Republic of",
  [ETranslation.PLACE_Monaco]: "Monaco",
  [ETranslation.PLACE_Mongolia]: "Mongolia",
  [ETranslation.PLACE_Montenegro]: "Montenegro",
  [ETranslation.PLACE_Montserrat]: "Montserrat",
  [ETranslation.PLACE_Morocco]: "Morocco",
  [ETranslation.PLACE_Mozambique]: "Mozambique",
  [ETranslation.PLACE_Myanmar]: "Myanmar",
  [ETranslation.PLACE_Namibia]: "Namibia",
  [ETranslation.PLACE_Nauru]: "Nauru",
  [ETranslation.PLACE_Nepal]: "Nepal",
  [ETranslation.PLACE_Netherlands]: "Netherlands",
  [ETranslation.PLACE_New_Caledonia]: "New Caledonia",
  [ETranslation.PLACE_New_Zealand]: "New Zealand",
  [ETranslation.PLACE_Nicaragua]: "Nicaragua",
  [ETranslation.PLACE_Niger]: "Niger",
  [ETranslation.PLACE_Nigeria]: "Nigeria",
  [ETranslation.PLACE_Niue]: "Niue",
  [ETranslation.PLACE_Norfolk_Island]: "Norfolk Island",
  [ETranslation.PLACE_Northern_Mariana_Islands]: "Northern Mariana Islands",
  [ETranslation.PLACE_Norway]: "Norway",
  [ETranslation.PLACE_Oman]: "Oman",
  [ETranslation.PLACE_Pakistan]: "Pakistan",
  [ETranslation.PLACE_Palau]: "Palau",
  [ETranslation.PLACE_Palestinian_Territory_Occupied]: "Palestinian Territory, Occupied",
  [ETranslation.PLACE_Panama]: "Panama",
  [ETranslation.PLACE_Papua_New_Guinea]: "Papua New Guinea",
  [ETranslation.PLACE_Paraguay]: "Paraguay",
  [ETranslation.PLACE_Peru]: "Peru",
  [ETranslation.PLACE_Philippines]: "Philippines",
  [ETranslation.PLACE_Pitcairn]: "Pitcairn",
  [ETranslation.PLACE_Poland]: "Poland",
  [ETranslation.PLACE_Portugal]: "Portugal",
  [ETranslation.PLACE_Puerto_Rico]: "Puerto Rico",
  [ETranslation.PLACE_Qatar]: "Qatar",
  [ETranslation.PLACE_Réunion]: "Réunion",
  [ETranslation.PLACE_Romania]: "Romania",
  [ETranslation.PLACE_Russian_Federation]: "Russian Federation",
  [ETranslation.PLACE_Rwanda]: "Rwanda",
  [ETranslation.PLACE_Saint_Barthélemy]: "Saint Barthélemy",
  [ETranslation.PLACE_Saint_Helena_Ascension_and_Tristan_da_Cunha]: "Saint Helena, Ascension and Tristan da Cunha",
  [ETranslation.PLACE_Saint_Kitts_and_Nevis]: "Saint Kitts and Nevis",
  [ETranslation.PLACE_Saint_Lucia]: "Saint Lucia",
  [ETranslation.PLACE_Saint_Martin_French_part]: "Saint Martin (French part)",
  [ETranslation.PLACE_Saint_Pierre_and_Miquelon]: "Saint Pierre and Miquelon",
  [ETranslation.PLACE_Saint_Vincent_and_the_Grenadines]: "Saint Vincent and the Grenadines",
  [ETranslation.PLACE_Samoa]: "Samoa",
  [ETranslation.PLACE_San_Marino]: "San Marino",
  [ETranslation.PLACE_Sao_Tome_and_Principe]: "Sao Tome and Principe",
  [ETranslation.PLACE_Saudi_Arabia]: "Saudi Arabia",
  [ETranslation.PLACE_Senegal]: "Senegal",
  [ETranslation.PLACE_Serbia]: "Serbia",
  [ETranslation.PLACE_Seychelles]: "Seychelles",
  [ETranslation.PLACE_Sierra_Leone]: "Sierra Leone",
  [ETranslation.PLACE_Singapore]: "Singapore",
  [ETranslation.PLACE_Sint_Maarten_Dutch_part]: "Sint Maarten (Dutch part)",
  [ETranslation.PLACE_Slovakia]: "Slovakia",
  [ETranslation.PLACE_Slovenia]: "Slovenia",
  [ETranslation.PLACE_Solomon_Islands]: "Solomon Islands",
  [ETranslation.PLACE_Somalia]: "Somalia",
  [ETranslation.PLACE_South_Africa]: "South Africa",
  [ETranslation.PLACE_South_Georgia_and_the_South_Sandwich_Islands]: "South Georgia and the South Sandwich Islands",
  [ETranslation.PLACE_South_Sudan]: "South Sudan",
  [ETranslation.PLACE_Spain]: "Spain",
  [ETranslation.PLACE_Sri_Lanka]: "Sri Lanka",
  [ETranslation.PLACE_Sudan]: "Sudan",
  [ETranslation.PLACE_Suriname]: "Suriname",
  [ETranslation.PLACE_Svalbard_and_Jan_Mayen]: "Svalbard and Jan Mayen",
  [ETranslation.PLACE_Swaziland]: "Swaziland",
  [ETranslation.PLACE_Sweden]: "Sweden",
  [ETranslation.PLACE_Switzerland]: "Switzerland",
  [ETranslation.PLACE_Syrian_Arab_Republic]: "Syrian Arab Republic",
  [ETranslation.PLACE_Taiwan_Province_of_China]: "Taiwan, Province of China",
  [ETranslation.PLACE_Tajikistan]: "Tajikistan",
  [ETranslation.PLACE_Tanzania_United_Republic_of]: "Tanzania, United Republic of",
  [ETranslation.PLACE_Thailand]: "Thailand",
  [ETranslation.PLACE_Timor_Leste]: "Timor-Leste",
  [ETranslation.PLACE_Togo]: "Togo",
  [ETranslation.PLACE_Tokelau]: "Tokelau",
  [ETranslation.PLACE_Tonga]: "Tonga",
  [ETranslation.PLACE_Trinidad_and_Tobago]: "Trinidad and Tobago",
  [ETranslation.PLACE_Tunisia]: "Tunisia",
  [ETranslation.PLACE_Turkey]: "Turkey",
  [ETranslation.PLACE_Turkmenistan]: "Turkmenistan",
  [ETranslation.PLACE_Turks_and_Caicos_Islands]: "Turks and Caicos Islands",
  [ETranslation.PLACE_Tuvalu]: "Tuvalu",
  [ETranslation.PLACE_Uganda]: "Uganda",
  [ETranslation.PLACE_Ukraine]: "Ukraine",
  [ETranslation.PLACE_United_Arab_Emirates]: "United Arab Emirates",
  [ETranslation.PLACE_United_Kingdom]: "United Kingdom",
  [ETranslation.PLACE_United_States]: "United States",
  [ETranslation.PLACE_United_States_Minor_Outlying_Islands]: "United States Minor Outlying Islands",
  [ETranslation.PLACE_Uruguay]: "Uruguay",
  [ETranslation.PLACE_Uzbekistan]: "Uzbekistan",
  [ETranslation.PLACE_Vanuatu]: "Vanuatu",
  [ETranslation.PLACE_Venezuela_Bolivarian_Republic_of]: "Venezuela, Bolivarian Republic of",
  [ETranslation.PLACE_Viet_Nam]: "Viet Nam",
  [ETranslation.PLACE_Virgin_Islands_British]: "Virgin Islands, British",
  [ETranslation.PLACE_Virgin_Islands_US]: "Virgin Islands, U.S.",
  [ETranslation.PLACE_Wallis_and_Futuna]: "Wallis and Futuna",
  [ETranslation.PLACE_Western_Sahara]: "Western Sahara",
  [ETranslation.PLACE_Yemen]: "Yemen",
  [ETranslation.PLACE_Zambia]: "Zambia",
  [ETranslation.PLACE_Zimbabwe]: "Zimbabwe",
}

const validationTranslations = {
  [ETranslation.VALIDATION_EMAIL]: "Check email",
  [ETranslation.VALIDATION_PHONE_NUMBER]: "Check phone number",
  [ETranslation.VALIDATION_MIN_LENGTH]: "Input minimum of {{minLength}} characters.",
  [ETranslation.VALIDATION_MAX_LENGTH]: "Input maximum {{maxLength}} characters.",
  [ETranslation.VALIDATION_MIN_AMOUNT]: "Amount cannot be lesser than {{minAmount}}.",
  [ETranslation.VALIDATION_MAX_AMOUNT]: "Amount cannot be greater than {{maxAmount}}.",
};

const translationsEn = {
  ...commonTranslations,
  ...authTranslations,
  ...insurancesTranslation,
  ...expressCheckoutTranslations,
  ...placeTranslations,
  ...insuranceTranslation,
  ...validationTranslations,
};

export default translationsEn;
