import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IAuthAction, IReservation } from "../../interfaces";
import { Dispatch } from "redux";
import { AuthUser } from "../../classes/AuthUser";
import { AxiosResponse } from "axios";
import { ELocalStorageKeys } from "../../data/local-storage";

export const storeAuthRedirect = (redirect: string): IAuthAction => {
  return {
    type: ActionTypes.STORE_AUTH_REDIRECT,
    redirect,
  };
};

const authStart = (): IAuthAction => {
  return {
    type: ActionTypes.AUTH_START,
  };
};

const authSuccess = (reservation: IReservation): IAuthAction => {
  return {
    type: ActionTypes.AUTH_SUCCESS,
    reservation,
  };
};

const authFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.AUTH_FAIL,
    error,
  };
};

export const auth = (email: string, irn: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(authStart());
    try {
      const res = await axios.get(
        `/json/checkout/read?irn=${irn}&email=${email}`
      );
      dispatch(authSuccess(res.data));
      localStorage.setItem(ELocalStorageKeys.checkoutIrn, irn);
      localStorage.setItem(ELocalStorageKeys.checkoutEmail, email);
    } catch ({ response }) {
      dispatch(authFail((response as AxiosResponse).data));
    }
  };
};

const logoutStart = (): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_START,
  };
};

const logoutSuccess = (): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
  };
};

const logoutFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.LOGOUT_FAIL,
    error,
  };
};

export const logout = () => {
  return async (dispatch: any) => {
    dispatch(logoutStart());
    try {
      await axios.put(`/admin`);
      dispatch(logoutSuccess());
    } catch (error) {
      dispatch(logoutFail(error as string));
    }
  };
};

export const clearError = (): IAuthAction => {
  return {
    type: ActionTypes.CLEAR_ERROR,
  };
};

export const getStoredReservation = (
  reservation: IReservation
): IAuthAction => {
  return {
    type: ActionTypes.GET_STORED_RESERVATION,
    reservation,
  };
};

const loginStart = (): IAuthAction => {
  return {
    type: ActionTypes.LOGIN_START,
  };
};

const loginSuccess = (user: AuthUser | null): IAuthAction => {
  return {
    type: ActionTypes.LOGIN_SUCCESS,
    user,
  };
};

const loginFail = (error: string): IAuthAction => {
  return {
    type: ActionTypes.LOGIN_FAIL,
    error,
  };
};

export const login = (email?: string, password?: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(loginStart());
    try {
      const res = await axios.post(`/admin`, { email, password });
      const user = new AuthUser(res.data.user);
      dispatch(loginSuccess(user));
    } catch ({ response }) {
      dispatch(loginFail(response as string));
    }
  };
};

export const clearReservation = (): IAuthAction => {
  localStorage.removeItem(ELocalStorageKeys.checkoutEmail);
  localStorage.removeItem(ELocalStorageKeys.checkoutIrn);
  return {
    type: ActionTypes.CLEAR_RESERVATION,
  };
};
