import React, { ReactNode } from "react";

export enum EModalSize {
  FULL,
  MEDIUM,
  SMALL,
}

export interface IModal {
  content: ReactNode;
  isOpen: boolean;
  title?: string;
  size: EModalSize;
  loading?: boolean;
  disabled?: boolean;
  onModalClose?: () => void;
  noScroll?: boolean;
}

export const defaultModal: IModal = {
  content: null,
  isOpen: false,
  title: "",
  size: EModalSize.SMALL,
  loading: false,
  disabled: false,
  onModalClose: () => {},
};

const ModalContext = React.createContext<{
  setModal: (modal: React.SetStateAction<IModal>) => void;
  closeModal: () => void;
}>({
  setModal: (modal: React.SetStateAction<IModal>) => {},
  closeModal: () => {},
});

export default ModalContext;
