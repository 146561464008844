import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Routes } from "../../../classes/Routes";
import Alert from "../../../components/UI/Alert/Alert";
import Button from "../../../components/UI/Button/Button";
import Container from "../../../components/UI/Container/Container";
import {
  EInputType,
  IInputField,
  IOption,
} from "../../../components/UI/Input/Input";
import InputGroup from "../../../components/UI/InputGroup/InputGroup";
import Spinner from "../../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../../context/InputContext";
import { placeOptions } from "../../../data/select-data";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { useConfirmModal } from "../../../hooks/useConfirmModal";
import { useCreateInput } from "../../../hooks/useCreateInput";
import {
  IAppState,
  IAuthState,
  IInsurance,
  IInsuranceAmendRequest,
  IInsuranceCancelRequest,
  IInsurancePriceRequest,
  IInsuranceSaveRequest,
  IInsuranceState,
  IPriceQuoteState,
  IStationState,
} from "../../../interfaces";
import { getInputData, initForm } from "../../../shared/utility";
import * as actions from "../../../store/actions";
import { ETranslation } from "../../../translations/translation-keys";

export enum EInsurance {
  startDate = "startDate",
  endDate = "endDate",
  ISOCurrency = "ISOCurrency",
  pickUpLocation = "pickUpLocation",
  firstName = "firstName",
  lastName = "lastName",
  email = "email",
  address = "address",
  city = "city",
  zip = "zip",
  country = "country",
  phone = "phone",
  debug = "debug",
  bookingIrn = "bookingIrn",
  returnLocation = "returnLocation",
}

const Insurance: React.FC = () => {
  const params = useParams();
  const id = useMemo(() => params.id ?? "", [params.id]);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const { createConfirmModal } = useConfirmModal();

  const { loading, error, insurance, saveOrUpdateOk } = useSelector<
    IAppState,
    IInsuranceState
  >((state) => state.insurance);
  const { /*loading: stationLoading, error: stationError,*/ stations } =
    useSelector<IAppState, IStationState>((state) => state.station);
  const {
    loading: priceQuoteLoading,
    error: priceQuoteError,
    priceQuote,
  } = useSelector<IAppState, IPriceQuoteState>((state) => state.priceQuote);
  const { authUser } = useSelector<IAppState, IAuthState>(
    (state) => state.auth
  );

  let canceled = false;
  if (insurance !== null && insurance?.status === "canceled") {
    canceled = true;
  }

  let stationOptions: IOption[] = [];
  if (stations) {
    stationOptions = stations.map((item) => {
      return { value: item.code ?? "", label: item.name };
    });
  }

  const [inputs, setInputs] = useState<IInputField>({
    [EInsurance.startDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.INSURANCE_START_DATE,
      value: "",
    },
    [EInsurance.endDate]: {
      type: EInputType.date,
      labelTranslation: ETranslation.INSURANCE_END_DATE,
      value: "",
    },
    [EInsurance.ISOCurrency]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_ISOCURRENCY,
      value: "",
    },
    [EInsurance.country]: {
      type: EInputType.select,
      labelTranslation: ETranslation.INSURANCE_PICKUP_COUNTRYISO,
      options: placeOptions,
      value: "",
    },
    [EInsurance.pickUpLocation]: {
      type: EInputType.select,
      labelTranslation: ETranslation.INSURANCE_PICKUP_LOCATION,
      value: "",
    },
    [EInsurance.firstName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_FIRSTNAME,
      value: "",
    },
    [EInsurance.lastName]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_LASTNAME,
      value: "",
    },
    [EInsurance.email]: {
      type: EInputType.text,
      labelTranslation: ETranslation.EMAIL,
      value: "",
    },
    [EInsurance.address]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_ADDRESS,
      value: "",
    },
    [EInsurance.city]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_CITY,
      value: "",
    },
    [EInsurance.zip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_ZIP,
      value: "",
    },
    [EInsurance.phone]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_PHONE,
      value: "",
    },
    [EInsurance.bookingIrn]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_BOOKING_IRN,
      value: "",
    },
    [EInsurance.returnLocation]: {
      type: EInputType.select,
      labelTranslation: ETranslation.INSURANCE_RETURN_LOCATION,
      value: "",
    },
  });

  const createInsuranceInput = useCreateInput(
    inputs,
    setInputs,
    EInputUpdateAction.INSURANCE
  );

  useEffect(() => {
    if (saveOrUpdateOk) {
      alert("Success");
      navigate(Routes.INSURANCES);
    }
    dispatch(actions.clearInsurance());
  }, [dispatch, saveOrUpdateOk, navigate]);

  useEffect(() => {
    if (Routes.isNotNew(id)) {
      dispatch(actions.getInsurance(id));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (insurance) {
      initForm(setInputs, insurance);
    }
  }, [insurance]);

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (Routes.isNew(id)) {
      setConfirmSaveDialog();
    } else {
      setConfirmAmendDialog();
    }
  };

  const saveInsurance = () => {
    //save
    const insuranceInputs = getInputData<IInsurance>(inputs);

    const saveRequest: IInsuranceSaveRequest = {
      country: insuranceInputs.country,
      insurance: {
        price: priceQuote?.price,
        tax: priceQuote?.tax,
        currency: priceQuote?.currency,
        quoteRef: priceQuote?.quoteRef,
      },
      booking: {
        irn: insuranceInputs.bookingIrn,
        dateRange: {
          from: insuranceInputs.startDate,
          to: insuranceInputs.endDate,
        },
        customer: {
          firstName: insuranceInputs.firstName,
          lastName: insuranceInputs.lastName,
          email: insuranceInputs.email,
          phone: insuranceInputs.phone,
          address: insuranceInputs.address,
          zip: insuranceInputs.zip,
          city: insuranceInputs.city,
        },
        pickupLocation: { ext: insuranceInputs.pickUpLocation },
        returnLocation: { ext: insuranceInputs.returnLocation },
        currency: insuranceInputs.ISOCurrency,
      },
      debug: false,
      source: "Insurance management",
      user: authUser?.id.toString(),
    };

    console.log("saveRequest");
    console.log(saveRequest);
    dispatch(actions.saveInsurance(saveRequest));
  };

  const amendInsurance = () => {
    //amend
    const insuranceInputs = getInputData<IInsurance>(inputs);

    const amendRequest: IInsuranceAmendRequest = {
      country: insuranceInputs.country,
      insurance: {
        price: priceQuote?.price,
        tax: priceQuote?.tax,
        currency: priceQuote?.currency,
        quoteRef: priceQuote?.quoteRef,
      },
      booking: {
        dateRange: {
          from: insuranceInputs.startDate,
          to: insuranceInputs.endDate,
        },
        irn: insuranceInputs.bookingIrn,
        pickupLocation: { ext: insuranceInputs.pickUpLocation },
        returnLocation: { ext: insuranceInputs.returnLocation },
        currency: insuranceInputs.ISOCurrency,
      },
      debug: false,
      quoteGUID: insurance?.quoteGUID,
      keyString: insurance?.id,
      user: authUser?.id.toString(),
    };
    console.log("amendRequest");
    console.log(amendRequest);
    dispatch(actions.amendInsurance(amendRequest));
  };

  const cancelInsurance = () => {
    // cancel
    const cancelRequest: IInsuranceCancelRequest = {
      bookingRef: insurance?.bookingIrn,
      quoteGUID: insurance?.quoteGUID,
      debug: false,
      keyString: insurance?.id,
      user: authUser?.id.toString(),
    };
    dispatch(actions.cancelInsurance(cancelRequest));
  };

  const priceQuoteInsurance = () => {
    // priceQuote
    console.log("PriceQuoteInsurance()");
    const insuranceInputs = getInputData<IInsurance>(inputs);

    const priceRequest: IInsurancePriceRequest = {
      country: insuranceInputs.country,
      currency: insuranceInputs.ISOCurrency,
      pickupLocation: { ext: insuranceInputs.pickUpLocation },
      returnLocation: { ext: insuranceInputs.returnLocation },
      dateRange: {
        from: insuranceInputs.startDate,
        to: insuranceInputs.endDate,
      },
      debug: false,
    };
    console.log("priceRequest");
    console.log(priceRequest);
    if (
      priceRequest.country &&
      priceRequest.pickupLocation &&
      priceRequest.returnLocation &&
      priceRequest?.dateRange?.from &&
      priceRequest.dateRange.to &&
      priceRequest.currency
    ) {
      dispatch(actions.priceQuoteInsurance(priceRequest));
    }
  };

  const setConfirmCancelDialog = () => {
    createConfirmModal(
      t(ETranslation.INSURANCE_MODAL_CANCEL_TITLE),
      t(ETranslation.INSURANCE_MODAL_CANCEL_BODY),
      cancelInsurance
    );
  };

  const setConfirmSaveDialog = () => {
    createConfirmModal(
      t(ETranslation.INSURANCE_MODAL_SAVE_TITLE),
      t(ETranslation.INSURANCE_MODAL_SAVE_BODY),
      saveInsurance
    );
  };

  const setConfirmAmendDialog = () => {
    createConfirmModal(
      t(ETranslation.INSURANCE_MODAL_AMEND_TITLE),
      t(ETranslation.INSURANCE_MODAL_AMEND_BODY),
      amendInsurance
    );
  };

  return (
    <Container>
      {error && <Alert text={error} />}
      <h2>
        {Routes.isNew(id)
          ? t(ETranslation.INSURANCE_NEW_TITLE)
          : t(ETranslation.INSURANCE)}
      </h2>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <InputGroup>
            {createInsuranceInput(EInsurance.country, {
              disabled: Routes.isNotNew(id),
              onBlur: priceQuoteInsurance,
              options: placeOptions,
            })}
            {createInsuranceInput(EInsurance.pickUpLocation, {
              disabled: Routes.isNotNew(id),
              onBlur: priceQuoteInsurance,
              options: stationOptions,
            })}
            {createInsuranceInput(EInsurance.returnLocation, {
              disabled: Routes.isNotNew(id),
              onBlur: priceQuoteInsurance,
              options: stationOptions,
            })}
            {createInsuranceInput(EInsurance.ISOCurrency, {
              disabled: Routes.isNotNew(id),
              onBlur: priceQuoteInsurance,
            })}
          </InputGroup>
          <InputGroup>
            {createInsuranceInput(EInsurance.startDate, {
              onBlur: priceQuoteInsurance,
              disabled: canceled,
            })}
            {createInsuranceInput(EInsurance.endDate, {
              onBlur: priceQuoteInsurance,
              disabled: canceled,
            })}
            {createInsuranceInput(EInsurance.bookingIrn, {
              disabled: Routes.isNotNew(id),
            })}
          </InputGroup>
          {priceQuoteLoading ? (
            <Spinner />
          ) : (
            <>
              {priceQuote && priceQuote.price && priceQuote.tax ? (
                <p>
                  <b>
                    {t(ETranslation.INSURANCE_PRICE_LABEL)}:{" "}
                    {Math.round(+priceQuote.price * 100) / 100}{" "}
                    {priceQuote.currency}
                  </b>
                  <br></br>
                  {t(ETranslation.INSURANCE_TAX_LABEL)}:{" "}
                  {Math.round(+priceQuote.tax * 100) / 100}{" "}
                  {priceQuote.currency}
                </p>
              ) : insurance && insurance.price && insurance.tax ? (
                <p>
                  <b>
                    {t(ETranslation.INSURANCE_PRICE_LABEL)}:{" "}
                    {Math.round(+insurance.price * 100) / 100}{" "}
                    {insurance.ISOCurrency}
                  </b>
                  <br></br>
                  {t(ETranslation.INSURANCE_TAX_LABEL)}:{" "}
                  {Math.round(+insurance.tax * 100) / 100}{" "}
                  {insurance.ISOCurrency}
                </p>
              ) : (
                <></>
              )}
              {priceQuoteError /*&& <Alert text={priceQuoteError} />*/}
            </>
          )}
          {Routes.isNew(id) && (
            <>
              <h3>{t(ETranslation.INSURANCES_CUSTOMER_DETAILS_TITLE)}</h3>
              <InputGroup>
                {createInsuranceInput(EInsurance.firstName)}
                {createInsuranceInput(EInsurance.lastName)}
              </InputGroup>
              <InputGroup>
                {createInsuranceInput(EInsurance.email)}
                {createInsuranceInput(EInsurance.phone)}
              </InputGroup>
              <InputGroup>
                {createInsuranceInput(EInsurance.address)}
              </InputGroup>
              <InputGroup>
                {createInsuranceInput(EInsurance.zip)}
                {createInsuranceInput(EInsurance.city)}
              </InputGroup>
            </>
          )}
        </>
      )}
      {!canceled && (
        <InputGroup>
          <Button
            style={{ marginRight: "15px" }}
            disabled={loading || !priceQuote || canceled}
            onClick={submitHandler}
          >
            {t(ETranslation.INSURANCE_SAVE_BUTTON)}
          </Button>
          {Routes.isNotNew(id) && (
            <Button disabled={loading} onClick={setConfirmCancelDialog}>
              {t(ETranslation.INSURANCE_CANCEL_BUTTON)}
            </Button>
          )}
        </InputGroup>
      )}
      {insurance != null && insurance.auditTrail != null ? (
        <h3 style={{ marginTop: "50px" }}>
          {t(ETranslation.INSURANCE_AUDIT_TRAIL_CHANGE_LOG)}
        </h3>
      ) : (
        <></>
      )}
      {insurance != null &&
        insurance.auditTrail != null &&
        insurance.auditTrail.map((auditTrail, index) => (
          <p key={index} style={{ color: "darkgrey" }}>
            {auditTrail.created} {auditTrail.eventString}: {auditTrail.oldValue}{" "}
            {"->"} {auditTrail.newValue}
            {auditTrail.user != null
              ? " By user: " +
                auditTrail.user.firstName +
                " " +
                auditTrail.user.lastName
              : ""}
            <br></br>
          </p>
        ))}
    </Container>
  );
};

export default Insurance;
