import {
  faChevronDown,
  faChevronUp,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ERouteStaticParams, Routes } from "../../classes/Routes";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Spinner from "../../components/UI/Spinner/Spinner";
import Table from "../../components/UI/Table/Table";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { IAppState, IInsurance, IInsuranceState } from "../../interfaces";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./Insurances.module.scss";

enum EInsurancesSortBy {
  STATUS = "status",
  CREATED = "created",
  START_DATE = "startDate",
  END_DATE = "endDate",
  STATION = "pickUpLocation",
  NAME = "firstName",
  EMAIL = "email",
}

const Insurances: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { loading, error, insurances } = useSelector<
    IAppState,
    IInsuranceState
  >((state) => state.insurance);

  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked(!checked);
  };

  const [sortedInsurances, setSortedInsurances] = useState<IInsurance[]>();
  const [sort, setSort] = useState<EInsurancesSortBy>();
  // true = up, false = down
  const [sortDirection, setSortDirection] = useState<boolean>(false);

  useEffect(() => {
    if (sortedInsurances != null) {
      const newInsurances = [...sortedInsurances];

      if (sort && newInsurances) {
        if (sortDirection) {
          newInsurances.reverse();
          setSortedInsurances(newInsurances);
          return;
        }

        newInsurances.sort((a, b) => {
          if (a[sort] === "" || a[sort] === null || a[sort] === undefined)
            return 1;
          if (b[sort] === "" || b[sort] === null || b[sort] === undefined)
            return -1;
          if (a[sort] === b[sort]) return 0;
          if (
            sort === EInsurancesSortBy.CREATED ||
            EInsurancesSortBy.START_DATE ||
            EInsurancesSortBy.END_DATE
          ) {
            return (a[sort] || "") < (b[sort] || "") ? 1 : -1;
          }
          return (a[sort] || "") > (b[sort] || "") ? 1 : -1;
        });
        setSortedInsurances(newInsurances);
      }
    }
    // eslint-disable-next-line
  }, [sort, sortDirection]);

  const getChevron = () => {
    // console.log(sortDirection);
    return (
      <FontAwesomeIcon
        className={classes.Icon}
        icon={sortDirection ? faChevronUp : faChevronDown}
      />
    );
  };

  const changeSortOrDirection = (sortBy: EInsurancesSortBy) => {
    if (sortBy === sort) {
      setSortDirection(!sortDirection);
    } else {
      setSort(sortBy);
      setSortDirection(false);
    }
  };

  const showInsurance = (
    event: React.MouseEvent<HTMLTableRowElement>,
    id: string
  ) => {
    navigate(Routes.INSURANCE(id));
  };

  useEffect(() => {
    dispatch(actions.clearInsurance());
    dispatch(actions.clearPriceQuote());
    dispatch(actions.listInsurances(checked));
  }, [dispatch, checked]);

  useEffect(() => {
    if (insurances) {
      setSortedInsurances(insurances);
    }
  }, [insurances]);

  return (
    <>
      {error && <Alert text={error} />}
      <Container>
        <h1>{t(ETranslation.INSURANCES_TITLE)}</h1>
        <Button
          onClick={() => navigate(Routes.INSURANCE(ERouteStaticParams.new))}
          icon={faPlus}
          style={{ marginBottom: "1rem" }}
        >
          {t(ETranslation.INSURANCE_NEW_TITLE)}
        </Button>
        <div>
          <label>
            <input type="checkbox" checked={checked} onChange={handleChange} />
            Only show upcoming
          </label>
        </div>
        {loading ? (
          <Spinner />
        ) : insurances ? (
          <Table hover>
            <thead>
              <tr className={classes.TableHeader}>
                <th
                  onClick={() =>
                    changeSortOrDirection(EInsurancesSortBy.STATUS)
                  }
                >
                  {t(ETranslation.INSURANCES_STATUS)}
                  {sort === EInsurancesSortBy.STATUS && getChevron()}
                </th>
                <th
                  onClick={() =>
                    changeSortOrDirection(EInsurancesSortBy.CREATED)
                  }
                >
                  {t(ETranslation.INSURANCES_CREATED_DATE)}
                  {sort === EInsurancesSortBy.CREATED && getChevron()}
                </th>
                <th
                  onClick={() =>
                    changeSortOrDirection(EInsurancesSortBy.START_DATE)
                  }
                >
                  {t(ETranslation.INSURANCES_START_DATE)}
                  {sort === EInsurancesSortBy.START_DATE && getChevron()}
                </th>
                <th
                  onClick={() =>
                    changeSortOrDirection(EInsurancesSortBy.END_DATE)
                  }
                >
                  {t(ETranslation.INSURANCES_END_DATE)}
                  {sort === EInsurancesSortBy.END_DATE && getChevron()}
                </th>
                <th
                  onClick={() =>
                    changeSortOrDirection(EInsurancesSortBy.STATION)
                  }
                >
                  {t(ETranslation.INSURANCES_STATION)}
                  {sort === EInsurancesSortBy.STATION && getChevron()}
                </th>
                <th>{t(ETranslation.INSURANCES_IRN)}</th>
                <th
                  onClick={() => changeSortOrDirection(EInsurancesSortBy.NAME)}
                >
                  {t(ETranslation.INSURANCES_CUSTOMER_NAME)}
                  {sort === EInsurancesSortBy.NAME && getChevron()}
                </th>
                <th
                  onClick={() => changeSortOrDirection(EInsurancesSortBy.EMAIL)}
                >
                  {t(ETranslation.INSURANCES_CUSTOMER_EMAIL)}
                  {sort === EInsurancesSortBy.EMAIL && getChevron()}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedInsurances &&
                sortedInsurances.map((insurance) => (
                  <tr
                    key={insurance.id}
                    onClick={(e) =>
                      insurance.id && showInsurance(e, insurance.id)
                    }
                  >
                    <td>{insurance.status}</td>
                    <td>{insurance.created}</td>
                    <td>{insurance.startDate}</td>
                    <td>{insurance.endDate}</td>
                    <td>{insurance.pickUpLocation}</td>
                    <td>{insurance.bookingIrn}</td>
                    <td>
                      {insurance.firstName} {insurance.lastName}
                    </td>
                    <td>{insurance.email}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        ) : (
          <p>{t(ETranslation.NO_INSURANCES)}</p>
        )}
      </Container>
    </>
  );
};

export default Insurances;
