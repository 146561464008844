let hostname;
let port: string;
if(window && window.location) {
  hostname = window.location.hostname;
  port = window.location.port;
}
const isDevelopment = hostname && (hostname === 'localhost' || hostname.startsWith('192.168'));

let serverUrl = '';
if (isDevelopment) {
  serverUrl = `http://${hostname}:8080`;
} else {
  serverUrl = `http://${hostname}:8080`;  // TODO muuta testauksen jälkeen
}

export { isDevelopment, serverUrl, port};