import React, { useEffect, useMemo } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useLocation, useNavigate } from "react-router-dom";
import { ERoute } from "../../classes/Routes";
import CustomerCheckout from "../../containers/CustomerCheckout/CustomerCheckout";
import Error from "../../containers/Error/Error";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";
import Layout from "../Layout/Layout";

const CustomerApp: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { token, irn } = useMemo(() => {
    const params = new URLSearchParams(location.search);
    return { token: params.get("token") ?? "", irn: params.get("irn") ?? "" };
  }, [location.search]);

  const { reservation, error } = useAppSelector((state) => state.customer);

  useEffect(() => {
    console.log("params changed");
    if (token && irn) {
      dispatch(actions.getCustomerReservation(token, irn));
    } else {
      navigate(ERoute.CUSTOMER_ERROR);
    }
  }, [dispatch, token, irn, navigate]);

  useEffect(() => {
    if (error) {
      navigate(ERoute.CUSTOMER_ERROR);
    }
  }, [navigate, error]);

  return (
    <Layout authUser={null}>
      <Routes>
        <Route
          path={ERoute.CUSTOMER_CHECKOUT}
          element={<CustomerCheckout token={token} irn={irn} />}
        />
        <Route path={ERoute.CUSTOMER_ERROR} element={<Error />} />
        <Route
          path="/*"
          element={
            <Navigate
              to={
                reservation ? ERoute.CUSTOMER_CHECKOUT : ERoute.CUSTOMER_ERROR
              }
            />
          }
        />
      </Routes>
    </Layout>
  );
};

export default CustomerApp;
