import axios from "axios";
import moment from "moment-timezone";

const hostname = window && window.location && window.location.hostname;

let apiPath = "/";
let withCredentials = false;
if (hostname === "localhost") {
  apiPath = "http://localhost:8080/";
  withCredentials = true;
}

const instance = axios.create({
  baseURL: apiPath,
  withCredentials: withCredentials,
});

instance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers["Timezone"] = moment.tz.guess();
  }
  return config;
});

instance.interceptors.response.use(
  (config) => config,
  ({ response }) => {
    if (!response || response.status === 500 || response.status === 405) {
      response.data = "Server error! Please contact support.";
    }
    return Promise.reject({ response });
  }
);

export default instance;
