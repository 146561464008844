import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import GuardedRoute from "../../GuardedRoute";
import { ERoute } from "../../classes/Routes";
import Layout from "../../components/Layout/Layout";
import Spinner from "../../components/UI/Spinner/Spinner";
import Auth from "../../containers/Auth/Auth";
import Insurance from "../../containers/Insurances/Insurance/Insurance";
import Insurances from "../../containers/Insurances/Insurances";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";

const InsuranceApp: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const { authUser, success } = useAppSelector((state) => state.auth);

  const logoutHandler = () => {
    dispatch(actions.logout());
    navigate(ERoute.INSURANCE_ADMIN_LOGIN);
  };

  useEffect(() => {
    dispatch(actions.login());
  }, [dispatch]);

  useEffect(() => {
    console.log(success);
    if (authUser || success) {
      setLoading(false);
      dispatch(actions.getStations());
    }
  }, [dispatch, authUser, success]);

  return loading ? (
    <Spinner />
  ) : (
    <Layout authUser={authUser} onLogout={logoutHandler}>
      <Routes>
        {authUser && (
          <Route
            path={ERoute.INSURANCE}
            element={<GuardedRoute user={authUser} element={<Insurance />} />}
          />
        )}
        {authUser && (
          <Route
            path={ERoute.INSURANCES}
            element={<GuardedRoute user={authUser} element={<Insurances />} />}
          />
        )}
        {!authUser && (
          <Route path={ERoute.INSURANCE_ADMIN_LOGIN} element={<Auth />} />
        )}
        <Route
          path="/*"
          element={
            <Navigate
              to={authUser ? authUser.redirect : ERoute.INSURANCE_ADMIN_LOGIN}
            />
          }
        />
      </Routes>
    </Layout>
  );
};

export default InsuranceApp;
