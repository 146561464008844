import React from "react";
import { useAppSelector } from "../../hooks/useAppSelector";
import Container from "../../components/UI/Container/Container";

const Error: React.FC = () => {
  const { error } = useAppSelector((state) => state.customer);
  return <Container>{error ? error : "Reservation not found."}</Container>;
};

export default Error;
