import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IInsurance, IInsuranceAction, IInsuranceAmendRequest, IInsuranceCancelRequest, IInsuranceSaveRequest } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";

const getInsuranceStart = (): IInsuranceAction => {
  return {
    type: ActionTypes.GET_INSURANCE_START,
  };
};

const getInsuranceSuccess = (insurance: IInsurance): IInsuranceAction => {
  return {
    type: ActionTypes.GET_INSURANCE_SUCCESS,
    insurance,
  };
};

const getInsuranceFail = (error: string): IInsuranceAction => {
  return {
    type: ActionTypes.GET_INSURANCE_FAIL,
    error,
  };
};

export const getInsurance = (id: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getInsuranceStart());
    try {
      const res = await axios.get(`/json/insurances?id=${id}&timezoneOffset=${new Date().getTimezoneOffset() / 60 * (-1)}`);
      dispatch(getInsuranceSuccess(res.data));
    } catch ({ response }) {
      dispatch(getInsuranceFail((response as AxiosResponse).data));
    }
  };
};


const listInsurancesStart = (): IInsuranceAction => {
  return {
    type: ActionTypes.LIST_INSURANCES_START,
  };
};

const listInsurancesSuccess = (insurances: IInsurance[]): IInsuranceAction => {
  return {
    type: ActionTypes.LIST_INSURANCES_SUCCESS,
    insurances,
  };
};

const listInsurancesFail = (error: string): IInsuranceAction => {
  return {
    type: ActionTypes.LIST_INSURANCES_FAIL,
    error,
  };
};

export const listInsurances = (onlyUpcoming?: boolean) => {
  return async (dispatch: Dispatch) => {
    dispatch(listInsurancesStart());
    try {
      const res = await axios.get(`/json/insurances?timezoneOffset=${new Date().getTimezoneOffset() / 60 * (-1)}
        &onlyUpcoming=${onlyUpcoming ?? ""}`);
      dispatch(listInsurancesSuccess(res.data));
    } catch ({ response }) {
      dispatch(listInsurancesFail((response as AxiosResponse).data));
    }
  };
};


export const clearInsurance = () => {
  return {
    type: ActionTypes.CLEAR_INSURANCE,
  };
};


const saveInsuranceStart = (): IInsuranceAction => {
  return {
    type: ActionTypes.SAVE_INSURANCE_START,
  };
};

const saveInsuranceSuccess = (): IInsuranceAction => {
  return {
    type: ActionTypes.SAVE_INSURANCE_SUCCESS,
  };
};

const saveInsuranceFail = (error: string): IInsuranceAction => {
  return {
    type: ActionTypes.SAVE_INSURANCE_FAIL,
    error,
  };
};

export const saveInsurance = (saveRequest: IInsuranceSaveRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveInsuranceStart());
    try {
      const res = await axios.post(`/api/v1/insurances/save`, saveRequest);
      if (res.data.status === "ERROR") {
        dispatch(saveInsuranceFail(res.data.error.message));
      } else {
        dispatch(saveInsuranceSuccess());
      }
    } catch ({ response }) {
      dispatch(saveInsuranceFail((response as AxiosResponse).data));
    }
  };
};


const amendInsuranceStart = (): IInsuranceAction => {
  return {
    type: ActionTypes.AMEND_INSURANCE_START,
  };
};

const amendInsuranceSuccess = (): IInsuranceAction => {
  return {
    type: ActionTypes.AMEND_INSURANCE_SUCCESS,
  };
};

const amendInsuranceFail = (error: string): IInsuranceAction => {
  return {
    type: ActionTypes.AMEND_INSURANCE_FAIL,
    error,
  };
};

export const amendInsurance = (amendRequest: IInsuranceAmendRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(amendInsuranceStart());
    try {
      const res = await axios.post(`/api/v1/insurances/amend`, amendRequest);
      if (res.data.status === "ERROR") {
        dispatch(amendInsuranceFail(res.data.error.message));
      } else {
        dispatch(amendInsuranceSuccess());
      }
    } catch ({ response }) {
      dispatch(amendInsuranceFail((response as AxiosResponse).data));
    }
  };
};


const cancelInsuranceStart = (): IInsuranceAction => {
  return {
    type: ActionTypes.CANCEL_INSURANCE_START,
  };
};

const cancelInsuranceSuccess = (): IInsuranceAction => {
  return {
    type: ActionTypes.CANCEL_INSURANCE_SUCCESS,
  };
};

const cancelInsuranceFail = (error: string): IInsuranceAction => {
  return {
    type: ActionTypes.CANCEL_INSURANCE_FAIL,
    error,
  };
};

export const cancelInsurance = (cancelRequest: IInsuranceCancelRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(cancelInsuranceStart());
    try {
      const res = await axios.post(`/api/v1/insurances/cancel`, cancelRequest);
      if (res.data.status === "ERROR") {
        dispatch(cancelInsuranceFail(res.data.error.message));
      } else {
        dispatch(cancelInsuranceSuccess());
      }
    } catch ({ response }) {
      dispatch(cancelInsuranceFail((response as AxiosResponse).data));
    }
  };
};