import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ETranslation } from "../../../translations/translation-keys";
import Button from "../Button/Button";
import InputGroup from "../InputGroup/InputGroup";
import ModalBody from "../Modal/ModalBody/ModalBody";
import ModalFooter from "../Modal/ModalFooter/ModalFooter";

interface IProps {
  body?: ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({ body, onConfirm, onCancel }) => {
  const { t } = useTranslation();
  return (
    <>
      <ModalBody>
        {body}
      </ModalBody>
      <ModalFooter>
        <InputGroup>
          <Button onClick={onConfirm} style={{marginRight: "15px"}}>{t(ETranslation.COMMON_YES)}</Button>
          <Button onClick={onCancel}>{t(ETranslation.COMMON_NO)}</Button>
        </InputGroup>
      </ModalFooter>
    </>
  );
}

export default ConfirmDialog;
