import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IStationState, IStationAction } from "../../interfaces";

const initialState: IStationState = {
  stations: null,
  loading: false,
  error: null,
};

const getStationsStart = (state: IStationState, action: IStationAction): IStationState => {
  return updateObject(state, { 
    error: null, 
    loading: true, 
    stations: null });
};

const getStationsSuccess = (state: IStationState, action: IStationAction): IStationState => {
  return updateObject(state, {
    stations: action.stations,
    error: null,
    loading: false,
  });
};

const getStationsFail = (state: IStationState, action: IStationAction): IStationState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const reducer = (
  state: IStationState = initialState,
  action: IStationAction
): IStationState => {
  switch (action.type) {
    case ActionTypes.GET_STATIONS_START:
      return getStationsStart(state, action);
    case ActionTypes.GET_STATIONS_SUCCESS:
      return getStationsSuccess(state, action);
    case ActionTypes.GET_STATIONS_FAIL:
      return getStationsFail(state, action);
    default:
      return state;
  }
};

export default reducer;