import React from "react";
import { useContext } from "react";
import ConfirmDialog from "../components/UI/ConfirmDialog/ConfirmDialog";
import ModalContext, { defaultModal, EModalSize } from "../components/UI/Modal/ModalContext";

export const useConfirmModal = () => {
    const { setModal } = useContext(ModalContext);

    const createConfirmModal = (title: string, body: string, onConfirm: () => void) => {
      setModal({
        isOpen: true,
        title,
        size: EModalSize.SMALL,
        content: (
          <ConfirmDialog
            body={
              <p>
                {body}
              </p>
            }
            onConfirm={() => {
              onConfirm()
              setModal(defaultModal);
            }}
            onCancel={() => setModal(defaultModal)}
          />
        )
      });
    }
    return { createConfirmModal };
  }