import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router";
import { useNavigate } from "react-router-dom";
import { ERoute } from "../../classes/Routes";
import Layout from "../../components/Layout/Layout";
import Auth from "../../containers/Auth/Auth";
import ExpressCheckout from "../../containers/ExpressCheckout/ExpressCheckout";
import { ELocalStorageKeys } from "../../data/local-storage";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import * as actions from "../../store/actions";

const CheckoutApp: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { reservation } = useAppSelector((state) => state.auth);

  const logoutHandler = () => {
    dispatch(actions.clearReservation());
    navigate(ERoute.SELF_CHECKOUT_LOGIN);
  };

  useEffect(() => {
    const email = localStorage.getItem(ELocalStorageKeys.checkoutEmail);
    const irn = localStorage.getItem(ELocalStorageKeys.checkoutIrn);
    if (email && irn) {
      dispatch(actions.auth(email, irn));
    }
  }, [dispatch]);

  return (
    <Layout authUser={null} onLogout={logoutHandler}>
      <Routes>
        {reservation && (
          <Route path={ERoute.EXPRESS_CHECKOUT} element={<ExpressCheckout />} />
        )}
        {!reservation && (
          <Route path={ERoute.SELF_CHECKOUT_LOGIN} element={<Auth />} />
        )}
        <Route
          path="/*"
          element={
            <Navigate
              to={
                reservation
                  ? ERoute.EXPRESS_CHECKOUT
                  : ERoute.SELF_CHECKOUT_LOGIN
              }
            />
          }
        />
      </Routes>
    </Layout>
  );
};

export default CheckoutApp;
