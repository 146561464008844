import { AxiosResponse } from "axios";
import { Dispatch } from "redux";
import axios from "../../axios";
import {
  ICustomerAction,
  ICustomerReservation,
  IReservation,
} from "../../interfaces";
import { ActionTypes } from "./actionTypes";

const getCustomerReservationStart = (): ICustomerAction => {
  return {
    type: ActionTypes.CUSTOMER_RESERVATION_GET_START,
  };
};

const getCustomerReservationSuccess = (
  reservation: IReservation
): ICustomerAction => {
  return {
    type: ActionTypes.CUSTOMER_RESERVATION_GET_SUCCESS,
    reservation,
  };
};

const getCustomerReservationFail = (error: string): ICustomerAction => {
  return {
    type: ActionTypes.CUSTOMER_RESERVATION_GET_FAIL,
    error,
  };
};

export const getCustomerReservation = (token: string, irn: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(getCustomerReservationStart());
    try {
      const res = await axios.get(
        `/json/checkout/customer/get?token=${token}&irn=${irn}`
      );
      dispatch(getCustomerReservationSuccess(res.data));
    } catch ({ response }) {
      dispatch(getCustomerReservationFail((response as AxiosResponse).data));
    }
  };
};

const saveCustomerReservationStart = (): ICustomerAction => {
  return {
    type: ActionTypes.CUSTOMER_RESERVATION_SAVE_START,
  };
};

const saveCustomerReservationSuccess = (
  reservation: IReservation
): ICustomerAction => {
  return {
    type: ActionTypes.CUSTOMER_RESERVATION_SAVE_SUCCESS,
    reservation,
  };
};

const saveCustomerReservationFail = (error: string): ICustomerAction => {
  return {
    type: ActionTypes.CUSTOMER_RESERVATION_SAVE_FAIL,
    error,
  };
};

export const saveCustomerReservation = (
  token: string,
  irn: string,
  reservation: ICustomerReservation
) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveCustomerReservationStart());
    try {
      const res = await axios.post(
        `/json/checkout/customer/save?token=${token}&irn=${irn}`,
        reservation
      );
      dispatch(saveCustomerReservationSuccess(res.data));
    } catch ({ response }) {
      dispatch(saveCustomerReservationFail((response as AxiosResponse).data));
    }
  };
};

export const clearCustomerReservation = (): ICustomerAction => {
  return {
    type: ActionTypes.CLEAR_RESERVATION,
  };
};
