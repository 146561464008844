import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { ICustomerState, ICustomerAction } from "../../interfaces";

const initialState: ICustomerState = {
  reservation: null,
  loading: false,
  error: null,
  saveLoading: false,
  saveError: null,
  saveOk: false,
};

const getCustomerReservationStart = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, { error: null, loading: true, reservation: null });
};

const getCustomerReservationSuccess = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    reservation: action.reservation,
    error: null,
    loading: false,
  });
};

const getCustomerReservationFail = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const saveCustomerReservationStart = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    saveError: null,
    saveLoading: true,
    saveOk: false,
  });
};

const saveCustomerReservationSuccess = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    reservation: action.reservation,
    saveError: null,
    saveLoading: false,
    saveOk: true,
  });
};

const saveCustomerReservationFail = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    saveError: action.error,
    saveLoading: false,
    saveOk: false,
  });
};

const clearCustomerReservation = (
  state: ICustomerState,
  action: ICustomerAction
): ICustomerState => {
  return updateObject(state, {
    saveError: null,
    saveLoading: false,
    reservation: null,
  });
};

const reducer = (
  state: ICustomerState = initialState,
  action: ICustomerAction
): ICustomerState => {
  switch (action.type) {
    case ActionTypes.CUSTOMER_RESERVATION_GET_START:
      return getCustomerReservationStart(state, action);
    case ActionTypes.CUSTOMER_RESERVATION_GET_SUCCESS:
      return getCustomerReservationSuccess(state, action);
    case ActionTypes.CUSTOMER_RESERVATION_GET_FAIL:
      return getCustomerReservationFail(state, action);
    case ActionTypes.CUSTOMER_RESERVATION_SAVE_START:
      return saveCustomerReservationStart(state, action);
    case ActionTypes.CUSTOMER_RESERVATION_SAVE_SUCCESS:
      return saveCustomerReservationSuccess(state, action);
    case ActionTypes.CUSTOMER_RESERVATION_SAVE_FAIL:
      return saveCustomerReservationFail(state, action);
    case ActionTypes.CUSTOMER_RESERVATION_CLEAR:
      return clearCustomerReservation(state, action);
    default:
      return state;
  }
};

export default reducer;
