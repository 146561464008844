import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { ERoute } from "./classes/Routes";
import CheckoutApp from "./components/App/CheckoutApp";
import InsuranceApp from "./components/App/InsuranceApp";
import Modal from "./components/UI/Modal/Modal";
import ModalContext, {
  IModal,
  defaultModal,
} from "./components/UI/Modal/ModalContext";
import CustomerApp from "./components/App/CustomerApp";

const App: React.FC = () => {
  const [modal, setModal] = useState<IModal>(defaultModal);
  const location = useLocation();
  return (
    <ModalContext.Provider
      value={{
        setModal,
        closeModal: () => setModal(defaultModal),
      }}
    >
      <Modal modal={modal} />
      {location.pathname.startsWith("/customer") ? (
        <CustomerApp />
      ) : location.pathname.startsWith("/insurances") ||
        (location.pathname as ERoute) === ERoute.INSURANCE_ADMIN_LOGIN ? (
        <InsuranceApp />
      ) : (
        <CheckoutApp />
      )}
    </ModalContext.Provider>
  );
};

export default App;
