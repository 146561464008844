import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IExpressCheckoutAction, IReservation } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";

const saveReservationStart = (): IExpressCheckoutAction => {
  return {
    type: ActionTypes.SAVE_RESERVATION_START
  };
};

const saveReservationSuccess = (
  status: string
): IExpressCheckoutAction => {
  return {
    type: ActionTypes.SAVE_RESERVATION_SUCCESS,
    status
  };
};

const saveReservationFail = (error: string): IExpressCheckoutAction => {
  return {
    type: ActionTypes.SAVE_RESERVATION_FAIL,
    error
  };
};

export const saveReservation = (reservation: IReservation) => {
  return async (dispatch: Dispatch) => {
    dispatch(saveReservationStart());
    try {
      const res = await axios.post(
        "/json/checkout/save",
        reservation
      );
      const status = res.data;
      dispatch(saveReservationSuccess(status));
    } catch ({ response }) {
      dispatch(saveReservationFail((response as AxiosResponse).data));
    }
  };
};
