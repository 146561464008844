import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Alert from "../../components/UI/Alert/Alert";
import Button, { EButtonType } from "../../components/UI/Button/Button";
import Input, {
  EInputType,
  IInputField,
  IInputOptions,
} from "../../components/UI/Input/Input";
import InputGroup from "../../components/UI/InputGroup/InputGroup";
import Spinner from "../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../context/InputContext";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { ICustomerReservation } from "../../interfaces";
import {
  getInputData,
  initForm,
  updateInputHandler,
  validateInputs,
} from "../../shared/utility";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./CustomerCheckout.module.scss";
import { placeOptions } from "../../data/select-data";

export enum EExpressCheckout {
  customerEmail = "customerEmail",
  customerPhone = "customerPhone",
  customerAddress = "customerAddress",
  customerCity = "customerCity",
  customerCountry = "customerCountry",
  customerZip = "customerZip",
}

const CustomerCheckout: React.FC<{ token: string; irn: string }> = ({
  token,
  irn,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isValid, setIsValid] = useState(false);

  const { reservation, saveLoading, saveError, saveOk } = useAppSelector(
    (state) => state.customer
  );

  const [inputs, setInputs] = useState<IInputField>({
    [EExpressCheckout.customerEmail]: {
      type: EInputType.email,
      labelTranslation: ETranslation.EMAIL,
      placeholderTranslation: ETranslation.EMAIL,
      value: "",
      validation: {
        required: true,
      },
    },
    [EExpressCheckout.customerPhone]: {
      type: EInputType.tel,
      labelTranslation: ETranslation.INSURANCE_PHONE,
      placeholderTranslation: ETranslation.INSURANCE_PHONE,
      value: "",
      validation: {
        required: true,
      },
    },
    [EExpressCheckout.customerAddress]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_ADDRESS,
      placeholderTranslation: ETranslation.INSURANCE_ADDRESS,
      value: "",
    },
    [EExpressCheckout.customerCity]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_CITY,
      placeholderTranslation: ETranslation.INSURANCE_CITY,
      value: "",
    },
    [EExpressCheckout.customerCountry]: {
      type: EInputType.select,
      labelTranslation: ETranslation.INSURANCE_PICKUP_COUNTRYISO,
      placeholderTranslation: ETranslation.INSURANCE_PICKUP_COUNTRYISO,
      options: placeOptions,
      value: "",
    },
    [EExpressCheckout.customerZip]: {
      type: EInputType.text,
      labelTranslation: ETranslation.INSURANCE_ZIP,
      placeholderTranslation: ETranslation.INSURANCE_ZIP,
      value: "",
    },
  });

  useEffect(() => {
    if (reservation) {
      initForm(setInputs, reservation);
    }
  }, [reservation]);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const getReservationInfo = () => {
    return (
      <React.Fragment>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_REFERENCE_NUMBER)}</h3>
          {reservation?.refNo && <p>{reservation.refNo}</p>}
        </div>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_IRN)}</h3>
          {reservation?.irn && <p>{reservation.irn}</p>}
        </div>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_STATUS)}</h3>
          {reservation?.status && <p>{t(reservation.status)}</p>}
        </div>
      </React.Fragment>
    );
  };

  const getDetails = () => {
    return (
      <React.Fragment>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_PICKUP_INFORMATION)}</h3>
          {reservation?.pickupStation && (
            <p>
              Pick Up At: {reservation.pickupStation}{" "}
              {reservation.departureName ?? ""}
            </p>
          )}
          {reservation?.dateFrom && reservation.timeFrom && (
            <p>
              Rental Start: {reservation.dateFrom} {reservation.timeFrom}
            </p>
          )}
          {reservation?.departureStationObject && (
            <div className={classes.StationInfo}>
              <p>Address:</p>
              <div>
                <p>{reservation.departureStationObject.address}</p>
                <p>
                  {reservation.departureStationObject.zip}{" "}
                  {reservation.departureStationObject.city}
                </p>
              </div>
            </div>
          )}
          {reservation?.departureStationObject?.phoneNumber && (
            <p>Telephone: {reservation.departureStationObject.phoneNumber}</p>
          )}
        </div>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_RETURN_INFORMATION)}</h3>
          {reservation?.returnStation && (
            <p>
              Return To: {reservation.returnStation}{" "}
              {reservation.returnName ?? ""}
            </p>
          )}
          {reservation?.dateTo && reservation.timeTo && (
            <p>
              Rental End: {reservation.dateTo} {reservation.timeTo}
            </p>
          )}
          {reservation?.returnStationObject && (
            <div className={classes.StationInfo}>
              <p>Address:</p>
              <div>
                <p>{reservation.returnStationObject.address}</p>
                <p>
                  {reservation.returnStationObject.zip}{" "}
                  {reservation.returnStationObject.city}
                </p>
              </div>
            </div>
          )}
          {reservation?.returnStationObject?.phoneNumber && (
            <p>Telephone: {reservation.returnStationObject.phoneNumber}</p>
          )}
        </div>
      </React.Fragment>
    );
  };

  const createExpressCheckoutInput = (
    inputName: EExpressCheckout,
    options?: IInputOptions
  ) => {
    const item = inputs[inputName];
    return (
      <Input
        {...item}
        {...options}
        updateAction={EInputUpdateAction.EXPRESS_CHECKOUT}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
        disabled={saveLoading}
        showValidation={!isValid}
      />
    );
  };

  const submitHandler = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    const reservation = getInputData<ICustomerReservation>(inputs);
    dispatch(actions.saveCustomerReservation(token, irn, reservation));
    console.log(reservation);
  };

  if (!reservation) return <Spinner />;
  return (
    <form className={classes.Container}>
      <div>
        <h2>{t(ETranslation.TITLE_RESERVATION_INFORMATION)}</h2>
        <InputGroup>{getReservationInfo()}</InputGroup>
        <InputGroup>{getDetails()}</InputGroup>
        <h3>{t(ETranslation.TITLE_PERSONAL_INFORMATION)}</h3>
        <InputGroup>
          {createExpressCheckoutInput(EExpressCheckout.customerEmail)}
          {createExpressCheckoutInput(EExpressCheckout.customerPhone)}
        </InputGroup>
        <InputGroup>
          {createExpressCheckoutInput(EExpressCheckout.customerAddress)}
          {createExpressCheckoutInput(EExpressCheckout.customerCity)}
        </InputGroup>
        <InputGroup>
          {createExpressCheckoutInput(EExpressCheckout.customerCountry)}
          {createExpressCheckoutInput(EExpressCheckout.customerZip)}
        </InputGroup>
      </div>
      <div>
        <Button
          onClick={submitHandler}
          className={classes.Button}
          loading={saveLoading}
          disabled={!isValid}
          type={EButtonType.SUBMIT}
        >
          {t(ETranslation.COMMON_SAVE)}
        </Button>
      </div>
      {saveError && <Alert text={saveError} style={{ margin: "1rem 0" }} />}
      {!saveError && saveOk && <p>The reservation was updated successfully.</p>}
    </form>
  );
};

export default CustomerCheckout;
