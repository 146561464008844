import { IOrganization } from "../interfaces/domain";
import { ERoute } from "./Routes";

export enum EUserRole {
  SUPERADMIN = "SUPERADMIN",
  ADMIN = "ADMIN",
  AGENT = "AGENT",
}

interface IAuthUser {
  id: number;
  organization: IOrganization;
  firstName: string;
  lastName: string;
  email: string;
  role: EUserRole;
  accessToken: string;
}

export class AuthUser implements IAuthUser {
  public id: number;
  public organization: IOrganization;
  public firstName: string;
  public lastName: string;
  public email: string;
  public role: EUserRole;
  public accessToken: string;

  public constructor(authUser: IAuthUser) {
    this.id = authUser.id;
    this.organization = authUser.organization;
    this.firstName = authUser.firstName;
    this.lastName = authUser.lastName;
    this.email = authUser.email;
    this.role = authUser.role;
    this.accessToken = authUser.accessToken;
  }

  public get routes(): ERoute[] {
    const routes: ERoute[] = [];

    const { isAdmin } = this;

    if (isAdmin) {
      routes.push.apply(routes, [
        ERoute.INSURANCES, ERoute.INSURANCE
      ]);
    }

    return routes;
  }

  public get isAdmin(): boolean {
    return this.role === EUserRole.SUPERADMIN || this.role === EUserRole.ADMIN;
  }

  public get isAgent(): boolean {
    return this.role === EUserRole.AGENT;
  }

  public get redirect(): ERoute {
    return ERoute.INSURANCES;
  }
}
