import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import Alert from "../../components/UI/Alert/Alert";
import Button from "../../components/UI/Button/Button";
import Container from "../../components/UI/Container/Container";
import Input, { EInputType, IInputField, IInputOptions } from "../../components/UI/Input/Input";
import InputGroup from "../../components/UI/InputGroup/InputGroup";
import Spinner from "../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../context/InputContext";
import { placeOptions } from "../../data/select-data";
import { IAppState, IReservation } from "../../interfaces";
import { getInputData, initForm, updateInputHandler } from "../../shared/utility";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./ExpressCheckout.module.scss";

interface IStateProps {
  reservation: IReservation | null;
  isAuthenticated: boolean;
  authError?: string | null;
  authLoading?: boolean;
  expressCheckoutError?: string | null;
  expressCheckoutLoading?: boolean;
}

interface IDispatchProps {
  onSaveReservation: (reservation: IReservation) => Promise<string>;
}

interface IProps extends IStateProps, IDispatchProps { }

export enum EExpressCheckout {
  // AGENT = "agent",
  // REFNO = "refNo",
  // IRN = "irn",
  // PICKUP_STATION = "pickupStation",
  // RETURN_STATION = "returnStation",
  // DATE_FROM = "dateFrom",
  // TIME_FROM = "timeFrom",
  // DATE_TO = "dateTo",
  // TIME_TO = "timeTo",
  DOB = "dob",
  POB = "pob",
  LICNO = "licNo",
  LICPLACE = "licPlace",
  LICISSUEDATE = "licIssueDate",
  LICEXPDATE = "licExpDate",
  IDNO = "idNo",
  IDPLACE = "idPlace",
  IDISSUEDATE = "idIssueDate",
  IDEXPDATE = "idExpDate",
}

const ExpressCheckout: React.FC<IProps> = ({
  reservation,
  onSaveReservation,
  authLoading,
  authError,
  expressCheckoutError,
  expressCheckoutLoading,
}) => {
  const { t } = useTranslation();

  const [inputs, setInputs] = useState<IInputField>({
    // [EExpressCheckout.AGENT]: {
    //   type: EInputType.text,
    //   labelTranslation: ETranslation.AGENT,
    //   value: "",
    //   validation: {
    //     required: true
    //   },
    // },
    // [EExpressCheckout.REFNO]: {
    //   type: EInputType.text,
    //   labelTranslation: ETranslation.REFNO,
    //   value: "",
    //   validation: {
    //     required: true
    //   },
    // },
    // [EExpressCheckout.IRN]: {
    //   type: EInputType.text,
    //   labelTranslation: ETranslation.IRN,
    //   value: "",
    //   validation: {
    //     required: true
    //   },
    // },
    [EExpressCheckout.DOB]: {
      type: EInputType.date,
      labelTranslation: ETranslation.DOB,
      value: "",
    },
    [EExpressCheckout.POB]: {
      type: EInputType.text,
      labelTranslation: ETranslation.POB,
      value: "",
    },
    [EExpressCheckout.LICNO]: {
      type: EInputType.text,
      labelTranslation: ETranslation.LICNO,
      value: "",
    },
    [EExpressCheckout.LICPLACE]: {
      type: EInputType.select,
      labelTranslation: ETranslation.LICPLACE,
      placeholderTranslation: ETranslation.LICPLACE,
      options: placeOptions,
      value: "",
    },
    [EExpressCheckout.LICISSUEDATE]: {
      type: EInputType.date,
      labelTranslation: ETranslation.LICISSUEDATE,
      value: "",
    },
    [EExpressCheckout.LICEXPDATE]: {
      type: EInputType.date,
      labelTranslation: ETranslation.LICEXPDATE,
      value: "",
    },
    [EExpressCheckout.IDNO]: {
      type: EInputType.text,
      labelTranslation: ETranslation.IDNO,
      value: "",
    },
    [EExpressCheckout.IDPLACE]: {
      type: EInputType.select,
      labelTranslation: ETranslation.IDPLACE,
      placeholderTranslation: ETranslation.IDPLACE,
      options: placeOptions,
      value: "",
    },
    [EExpressCheckout.IDISSUEDATE]: {
      type: EInputType.date,
      labelTranslation: ETranslation.IDISSUEDATE,
      value: "",
    },
    [EExpressCheckout.IDEXPDATE]: {
      type: EInputType.date,
      labelTranslation: ETranslation.IDEXPDATE,
      value: "",
    },
  });

  useEffect(() => {
    if (reservation) {
      initForm(setInputs, reservation)
    }
  }, [reservation]);

  const getReservationInfo = () => {
    return (
      <React.Fragment>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_REFERENCE_NUMBER)}</h3>
          {reservation?.refNo && <p>{reservation.refNo}</p>}
        </div>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_IRN)}</h3>
          {reservation?.irn && <p>{reservation.irn}</p>}
        </div>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_STATUS)}</h3>
          {reservation?.status && <p>{t(reservation.status)}</p>}
        </div>
      </React.Fragment>
    );
  }

  const getDetails = () => {
    return (
      <React.Fragment>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_PICKUP_INFORMATION)}</h3>
          {reservation?.pickupStation && <p>{reservation.pickupStation}</p>}
          {reservation?.dateFrom && reservation.timeFrom && <p>{reservation.dateFrom} {reservation.timeFrom}</p>}
        </div>
        <div className={classes.ReservationInfo}>
          <h3>{t(ETranslation.TITLE_RETURN_INFORMATION)}</h3>
          {reservation?.returnStation && <p>{reservation.returnStation}</p>}
          {reservation?.dateTo && reservation.timeTo && <p>{reservation.dateTo} {reservation.timeTo}</p>}
        </div>
      </React.Fragment>
    );
  }

  const createExpressCheckoutInput = (inputName: EExpressCheckout, options?: IInputOptions) => {
    const item = inputs[inputName];
    return <Input
      {...item}
      {...options}
      updateAction={EInputUpdateAction.EXPRESS_CHECKOUT}
      onChange={value => updateInputHandler(inputName, value, setInputs)}
      inputName={inputName}
    />
  };

  const submitHandler = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    const expressCheckout = getInputData<IReservation>(inputs);
    if (reservation) {
      expressCheckout.irn = reservation.irn;
      // expressCheckout.refNo = reservation.refNo;
    }
    const res = await onSaveReservation(expressCheckout);
    console.log(expressCheckout, res);
  };

  return (
    <Container>
      <div className={classes.Container}>
        {((authLoading || expressCheckoutLoading) && <Spinner />) || (
          <div>
            <div className={classes.Inputs}>
              <h2>{t(ETranslation.TITLE_RESERVATION_INFORMATION)}</h2>
              <InputGroup>
                {getReservationInfo()}
              </InputGroup>
              <InputGroup>
                {/* {createExpressCheckoutInput(EExpressCheckout.AGENT, {
                    showValidation: true,
                  })} */}
                {/* {createExpressCheckoutInput(EExpressCheckout.REFNO, {
                    showValidation: true,
                  })}
                  {createExpressCheckoutInput(EExpressCheckout.IRN, {
                    showValidation: true,
                  })} */}
                {getDetails()}
              </InputGroup>
              <h3>{t(ETranslation.TITLE_PERSONAL_INFORMATION)}</h3>
              <InputGroup>
                {createExpressCheckoutInput(EExpressCheckout.DOB)}
                {createExpressCheckoutInput(EExpressCheckout.POB)}
              </InputGroup>
              <h3>{t(ETranslation.TITLE_LICENSE_INFORMATION)}</h3>
              <InputGroup>
                {createExpressCheckoutInput(EExpressCheckout.LICNO)}
                {createExpressCheckoutInput(EExpressCheckout.LICPLACE)}
                {createExpressCheckoutInput(EExpressCheckout.LICISSUEDATE)}
                {createExpressCheckoutInput(EExpressCheckout.LICEXPDATE)}
              </InputGroup>
              <h3>{t(ETranslation.TITLE_IDENTITY_INFORMATION)}</h3>
              <InputGroup>
                {createExpressCheckoutInput(EExpressCheckout.IDNO)}
                {createExpressCheckoutInput(EExpressCheckout.IDPLACE)}
                {createExpressCheckoutInput(EExpressCheckout.IDISSUEDATE)}
                {createExpressCheckoutInput(EExpressCheckout.IDEXPDATE)}
              </InputGroup>
            </div>
            <div className={classes.ButtonContainer}>
              <Button onClick={submitHandler} className={classes.Button}>
                {t(ETranslation.COMMON_SAVE)}
              </Button>
            </div>
            {authError && (
              <Alert className={classes.ErrorMessage} text={authError} />
            )}
            {expressCheckoutError && (
              <Alert className={classes.ErrorMessage} text={expressCheckoutError} />
            )}
          </div>
        )}
      </div>
    </Container>
  )
}

const mapStateToProps = (state: IAppState): IStateProps => {
  return {
    authLoading: state.auth.loading,
    authError: state.auth.error,
    isAuthenticated: state.auth.reservation !== null,
    reservation: state.auth.reservation,
    expressCheckoutLoading: state.expressCheckout.loading,
    expressCheckoutError: state.expressCheckout.error,
  };
};

const mapDispatchToProps = (dispatch: any): IDispatchProps => {
  return {
    onSaveReservation: (reservation) => dispatch(actions.saveReservation(reservation)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ExpressCheckout);
