import React from 'react';
import { AuthUser } from '../classes/AuthUser';

const HeaderContext = React.createContext<{
  onLogout?: () => void;
  authUser: AuthUser | null;
}>({
  onLogout: () => {},
  authUser: null,
});

export default HeaderContext;
