import React from "react";
import { useLocation } from "react-router-dom";
import { AuthUser } from "../../classes/AuthUser";
import { ERoute } from "../../classes/Routes";
import Navigation from "../../components/Navigation/Navigation";
import HeaderContext from "../../context/HeaderContext";
import classes from "./Layout.module.scss";

interface IProps {
  onLogout?: () => void;
  children: any;
  authUser: AuthUser | null;
}

const Layout: React.FC<IProps> = ({ authUser, onLogout, children }) => {
  const location = useLocation();
  const isLogin = [
    ERoute.INSURANCE_ADMIN_LOGIN,
    ERoute.SELF_CHECKOUT_LOGIN,
  ].includes(location.pathname as ERoute);
  return (
    <div className={classes.root}>
      {!isLogin && (
        <HeaderContext.Provider value={{ authUser, onLogout }}>
          <Navigation />
        </HeaderContext.Provider>
      )}
      <div className={!isLogin ? classes.Container : undefined}>{children}</div>
    </div>
  );
};

export default Layout;
