import axios from "../../axios";
import { ActionTypes } from "./actionTypes";

import { IInsurancePriceRequest, IInsurancePriceResponse, IPriceQuoteAction } from "../../interfaces";
import { Dispatch } from "redux";
import { AxiosResponse } from "axios";

const priceQuoteInsuranceStart = (): IPriceQuoteAction => {
  return {
    type: ActionTypes.PRICEQUOTE_INSURANCE_START,
  };
};

const priceQuoteInsuranceSuccess = (priceQuote: IInsurancePriceResponse): IPriceQuoteAction => {
  return {
    type: ActionTypes.PRICEQUOTE_INSURANCE_SUCCESS,
    priceQuote
  };
};

const priceQuoteInsuranceFail = (error: string): IPriceQuoteAction => {
  return {
    type: ActionTypes.PRICEQUOTE_INSURANCE_FAIL,
    error,
  };
};

export const priceQuoteInsurance = (priceRequest: IInsurancePriceRequest) => {
  return async (dispatch: Dispatch) => {
    dispatch(priceQuoteInsuranceStart());
    try {
      const res = await axios.post(`/api/v1/insurances/pricequote`, priceRequest);
      if (res.data.status === "ERROR") {
        dispatch(priceQuoteInsuranceFail(res.data.error.message));
      } else {
        dispatch(priceQuoteInsuranceSuccess(res.data));
      }
    } catch ({ response }) {
      dispatch(priceQuoteInsuranceFail((response as AxiosResponse).data));
    }
  };
};

export const clearPriceQuote = () => {
  return {
    type: ActionTypes.CLEAR_PRICEQUOTE,
  };
};