import React from "react";
import {
  matchRoutes,
  Navigate,
  RouteObject,
  useLocation,
} from "react-router-dom";
import { AuthUser } from "./classes/AuthUser";

interface IProps {
  user: AuthUser;
  element: JSX.Element;
}

const GuardedRoute: React.FC<IProps> = ({ user, element }) => {
  const location = useLocation();
  const routes: RouteObject[] = user.routes.map((route) => ({ path: route }));
  return matchRoutes(routes, location) ? (
    element
  ) : (
    <Navigate to={user.redirect} />
  );
};

export default GuardedRoute;
