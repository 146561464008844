export enum ETranslation {
  // Common
  COMMON_SAVE = "COMMON_SAVE",
  COMMON_YES = "COMMON_YES",
  COMMON_NO = "COMMON_NO",

  // Auth
  LOG_IN = "LOG_IN",

  // Auth Inputs
  EMAIL = "EMAIL",
  PASSWORD = "PASSWORD",

  // Insurances
  INSURANCES_TITLE = "INSURANCES_TITLE",
  INSURANCES_STATUS = "INSURANCES_STATUS",
  INSURANCES_CREATED_DATE = "INSURANCES_CREATED_DATE",
  INSURANCES_START_DATE = "INSURANCES_START_DATE",
  INSURANCES_END_DATE = "INSURANCES_END_DATE",
  INSURANCES_STATION = "INSURANCES_STATION",
  INSURANCES_IRN = "INSURANCES_IRN",
  INSURANCES_CUSTOMER_FIRST_NAME = "INSURANCES_CUSTOMER_FIRST_NAME",
  INSURANCES_CUSTOMER_LAST_NAME = "INSURANCES_CUSTOMER_LAST_NAME",
  INSURANCES_CUSTOMER_NAME = "INSURANCES_CUSTOMER_NAME",
  INSURANCES_CUSTOMER_EMAIL = "INSURANCES_CUSTOMER_EMAIL",
  INSURANCES_CUSTOMER_DETAILS_TITLE = "INSURANCES_CUSTOMER_DETAILS_TITLE",
  NO_INSURANCES = "NO_INSURANCES",

  // Insurance
  INSURANCE_SAVE_BUTTON = "INSURANCE_SAVE_BUTTON",
  INSURANCE_RETURN_BUTTON = "INSURANCE_RETURN_BUTTON",
  INSURANCE_CANCEL_BUTTON = "INSURANCE_CANCEL_BUTTON",
  INSURANCE_NEW_TITLE = "INSURANCE_NEW_TITLE",
  INSURANCE = "INSURANCE",
  INSURANCE_START_DATE = "INSURANCE_START_DATE",
  INSURANCE_END_DATE = "INSURANCE_END_DATE",
  INSURANCE_PRICE_LABEL = "INSURANCE_PRICE_LABEL",
  INSURANCE_TAX_LABEL = "INSURANCE_TAX_LABEL",
  INSURANCE_ISOCURRENCY = "INSURANCE_ISOCURRENCY",
  INSURANCE_BOOKING_REF = "INSURANCE_BOOKING_REF",
  INSURANCE_BOOKING_IRN = "INSURANCE_BOOKING_IRN",
  INSURANCE_PICKUP_COUNTRYISO = "INSURANCE_PICKUP_COUNTRYISO",
  INSURANCE_PICKUP_LOCATION = "INSURANCE_PICKUP_LOCATION",
  INSURANCE_FIRSTNAME = "INSURANCE_FIRSTNAME",
  INSURANCE_LASTNAME = "INSURANCE_LASTNAME",
  INSURANCE_EMAIL = "INSURANCE_EMAIL",
  INSURANCE_ADDRESS = "INSURANCE_ADDRESS",
  INSURANCE_CITY = "INSURANCE_CITY",
  INSURANCE_ZIP = "INSURANCE_ZIP",
  INSURANCE_PHONE = "INSURANCE_PHONE",
  INSURANCE_AUDIT_TRAIL = "INSURANCE_AUDIT_TRAIL",
  INSURANCE_DEBUG = "INSURANCE_DEBUG",
  INSURANCE_QUOTE_GUID = "INSURANCE_QUOTE_GUID",
  INSURANCE_PRICE_QUOTE = "INSURANCE_PRICE_QUOTE",
  INSURANCE_RETURN_LOCATION = "INSURANCE_RETURN_LOCATION",
  INSURANCE_AUDIT_TRAIL_CHANGE_LOG = "INSURANCE_AUDIT_TRAIL_CHANGE_LOG",
  INSURANCE_MODAL_CANCEL_TITLE = "INSURANCE_MODAL_CANCEL_TITLE",
  INSURANCE_MODAL_CANCEL_BODY = "INSURANCE_MODAL_CANCEL_BODY",
  INSURANCE_MODAL_AMEND_TITLE = "INSURANCE_MODAL_AMEND_TITLE",
  INSURANCE_MODAL_AMEND_BODY = "INSURANCE_MODAL_AMEND_BODY",
  INSURANCE_MODAL_SAVE_TITLE = "INSURANCE_MODAL_SAVE_TITLE",
  INSURANCE_MODAL_SAVE_BODY = "INSURANCE_MODAL_SAVE_BODY",

  // ExpressCheckout
  TITLE_RESERVATION_INFORMATION = "TITLE_RESERVATION_INFORMATION",
  TITLE_PERSONAL_INFORMATION = "TITLE_PERSONAL_INFORMATION",
  TITLE_LICENSE_INFORMATION = "TITLE_LICENSE_INFORMATION",
  TITLE_IDENTITY_INFORMATION = "TITLE_IDENTITY_INFORMATION",
  TITLE_PICKUP_INFORMATION = "TITLE_PICKUP_INFORMATION",
  TITLE_RETURN_INFORMATION = "TITLE_RETURN_INFORMATION",
  TITLE_REFERENCE_NUMBER = "TITLE_REFERENCE_NUMBER",
  TITLE_IRN = "TITLE_IRN",
  TITLE_STATUS = "TITLE_STATUS",
  
  CNC = "CNC",
  RES = "RES",
  REQ = "REQ",

  // ExpressCheckout Inputs
  AGENT = "AGENT",
  REFNO = "REFNO",
  DOB = "DOB",
  POB = "POB",
  LICNO = "LICNO",
  LICPLACE = "LICPLACE",
  LICISSUEDATE = "LICISSUEDATE",
  LICEXPDATE = "LICEXPDATE",
  IDNO = "IDNO",
  IDPLACE = "IDPLACE",
  IDISSUEDATE = "IDISSUEDATE",
  IDEXPDATE = "IDEXPDATE",

  // Shared Inputs
  IRN = "IRN",

  // Place select
  PLACE_Default = "PLACE_Default",
  PLACE_Afghanistan = "PLACE_Afghanistan",
  PLACE_Åland_Islands = "PLACE_Åland_Islands",
  PLACE_Albania = "PLACE_Albania",
  PLACE_Algeria = "PLACE_Algeria",
  PLACE_American_Samoa = "PLACE_American_Samoa",
  PLACE_Andorra = "PLACE_Andorra",
  PLACE_Angola = "PLACE_Angola",
  PLACE_Anguilla = "PLACE_Anguilla",
  PLACE_Antarctica = "PLACE_Antarctica",
  PLACE_Antigua_and_Barbuda = "PLACE_Antigua_and_Barbuda",
  PLACE_Argentina = "PLACE_Argentina",
  PLACE_Armenia = "PLACE_Armenia",
  PLACE_Aruba = "PLACE_Aruba",
  PLACE_Australia = "PLACE_Australia",
  PLACE_Austria = "PLACE_Austria",
  PLACE_Azerbaijan = "PLACE_Azerbaijan",
  PLACE_Bahamas = "PLACE_Bahamas",
  PLACE_Bahrain = "PLACE_Bahrain",
  PLACE_Bangladesh = "PLACE_Bangladesh",
  PLACE_Barbados = "PLACE_Barbados",
  PLACE_Belarus = "PLACE_Belarus",
  PLACE_Belgium = "PLACE_Belgium",
  PLACE_Belize = "PLACE_Belize",
  PLACE_Benin = "PLACE_Benin",
  PLACE_Bermuda = "PLACE_Bermuda",
  PLACE_Bhutan = "PLACE_Bhutan",
  PLACE_Bolivia_Plurinational_State_of = "PLACE_Bolivia_Plurinational_State_of",
  PLACE_Bonaire_Sint_Eustatius_and_Saba = "PLACE_Bonaire_Sint_Eustatius_and_Saba",
  PLACE_Bosnia_and_Herzegovina = "PLACE_Bosnia_and_Herzegovina",
  PLACE_Botswana = "PLACE_Botswana",
  PLACE_Bouvet_Island = "PLACE_Bouvet_Island",
  PLACE_Brazil = "PLACE_Brazil",
  PLACE_British_Indian_Ocean_Territory = "PLACE_British_Indian_Ocean_Territory",
  PLACE_Brunei_Darussalam = "PLACE_Brunei_Darussalam",
  PLACE_Bulgaria = "PLACE_Bulgaria",
  PLACE_Burkina_Faso = "PLACE_Burkina_Faso",
  PLACE_Burundi = "PLACE_Burundi",
  PLACE_Cambodia = "PLACE_Cambodia",
  PLACE_Cameroon = "PLACE_Cameroon",
  PLACE_Canada = "PLACE_Canada",
  PLACE_Cape_Verde = "PLACE_Cape_Verde",
  PLACE_Cayman_Islands = "PLACE_Cayman_Islands",
  PLACE_Central_African_Republic = "PLACE_Central_African_Republic",
  PLACE_Chad = "PLACE_Chad",
  PLACE_Chile = "PLACE_Chile",
  PLACE_China = "PLACE_China",
  PLACE_Christmas_Island = "PLACE_Christmas_Island",
  PLACE_Cocos_Keeling_Islands = "PLACE_Cocos_Keeling_Islands",
  PLACE_Colombia = "PLACE_Colombia",
  PLACE_Comoros = "PLACE_Comoros",
  PLACE_Congo = "PLACE_Congo",
  PLACE_Congo_the_Democratic_Republic_of_the = "PLACE_Congo_the_Democratic_Republic_of_the",
  PLACE_Cook_Islands = "PLACE_Cook_Islands",
  PLACE_Costa_Rica = "PLACE_Costa_Rica",
  PLACE_Côte_dIvoire = "PLACE_Côte_dIvoire",
  PLACE_Croatia = "PLACE_Croatia",
  PLACE_Cuba = "PLACE_Cuba",
  PLACE_Curaçao = "PLACE_Curaçao",
  PLACE_Cyprus = "PLACE_Cyprus",
  PLACE_Czech_Republic = "PLACE_Czech_Republic",
  PLACE_Denmark = "PLACE_Denmark",
  PLACE_Djibouti = "PLACE_Djibouti",
  PLACE_Dominica = "PLACE_Dominica",
  PLACE_Dominican_Republic = "PLACE_Dominican_Republic",
  PLACE_Ecuador = "PLACE_Ecuador",
  PLACE_Egypt = "PLACE_Egypt",
  PLACE_El_Salvador = "PLACE_El_Salvador",
  PLACE_Equatorial_Guinea = "PLACE_Equatorial_Guinea",
  PLACE_Eritrea = "PLACE_Eritrea",
  PLACE_Estonia = "PLACE_Estonia",
  PLACE_Ethiopia = "PLACE_Ethiopia",
  PLACE_Falkland_Islands_Malvinas = "PLACE_Falkland_Islands_Malvinas",
  PLACE_Faroe_Islands = "PLACE_Faroe_Islands",
  PLACE_Fiji = "PLACE_Fiji",
  PLACE_Finland = "PLACE_Finland",
  PLACE_France = "PLACE_France",
  PLACE_French_Guiana = "PLACE_French_Guiana",
  PLACE_French_Polynesia = "PLACE_French_Polynesia",
  PLACE_French_Southern_Territories = "PLACE_French_Southern_Territories",
  PLACE_Gabon = "PLACE_Gabon",
  PLACE_Gambia = "PLACE_Gambia",
  PLACE_Georgia = "PLACE_Georgia",
  PLACE_Germany = "PLACE_Germany",
  PLACE_Ghana = "PLACE_Ghana",
  PLACE_Gibraltar = "PLACE_Gibraltar",
  PLACE_Greece = "PLACE_Greece",
  PLACE_Greenland = "PLACE_Greenland",
  PLACE_Grenada = "PLACE_Grenada",
  PLACE_Guadeloupe = "PLACE_Guadeloupe",
  PLACE_Guam = "PLACE_Guam",
  PLACE_Guatemala = "PLACE_Guatemala",
  PLACE_Guernsey = "PLACE_Guernsey",
  PLACE_Guinea = "PLACE_Guinea",
  PLACE_Guinea_Bissau = "PLACE_Guinea_Bissau",
  PLACE_Guyana = "PLACE_Guyana",
  PLACE_Haiti = "PLACE_Haiti",
  PLACE_Heard_Island_and_McDonald_Islands = "PLACE_Heard_Island_and_McDonald_Islands",
  PLACE_Holy_See_Vatican_City_State = "PLACE_Holy_See_Vatican_City_State",
  PLACE_Honduras = "PLACE_Honduras",
  PLACE_Hong_Kong = "PLACE_Hong_Kong",
  PLACE_Hungary = "PLACE_Hungary",
  PLACE_Iceland = "PLACE_Iceland",
  PLACE_India = "PLACE_India",
  PLACE_Indonesia = "PLACE_Indonesia",
  PLACE_Iran_Islamic_Republic_of = "PLACE_Iran_Islamic_Republic_of",
  PLACE_Iraq = "PLACE_Iraq",
  PLACE_Ireland = "PLACE_Ireland",
  PLACE_Isle_of_Man = "PLACE_Isle_of_Man",
  PLACE_Israel = "PLACE_Israel",
  PLACE_Italy = "PLACE_Italy",
  PLACE_Jamaica = "PLACE_Jamaica",
  PLACE_Japan = "PLACE_Japan",
  PLACE_Jersey = "PLACE_Jersey",
  PLACE_Jordan = "PLACE_Jordan",
  PLACE_Kazakhstan = "PLACE_Kazakhstan",
  PLACE_Kenya = "PLACE_Kenya",
  PLACE_Kiribati = "PLACE_Kiribati",
  PLACE_Korea_Democratic_Peoples_Republic_of = "PLACE_Korea_Democratic_Peoples_Republic_of",
  PLACE_Korea_Republic_of = "PLACE_Korea_Republic_of",
  PLACE_Kuwait = "PLACE_Kuwait",
  PLACE_Kyrgyzstan = "PLACE_Kyrgyzstan",
  PLACE_Lao_Peoples_Democratic_Republic = "PLACE_Lao_Peoples_Democratic_Republic",
  PLACE_Latvia = "PLACE_Latvia",
  PLACE_Lebanon = "PLACE_Lebanon",
  PLACE_Lesotho = "PLACE_Lesotho",
  PLACE_Liberia = "PLACE_Liberia",
  PLACE_Libya = "PLACE_Libya",
  PLACE_Liechtenstein = "PLACE_Liechtenstein",
  PLACE_Lithuania = "PLACE_Lithuania",
  PLACE_Luxembourg = "PLACE_Luxembourg",
  PLACE_Macao = "PLACE_Macao",
  PLACE_Macedonia_the_former_Yugoslav_Republic_of = "PLACE_Macedonia_the_former_Yugoslav_Republic_of",
  PLACE_Madagascar = "PLACE_Madagascar",
  PLACE_Malawi = "PLACE_Malawi",
  PLACE_Malaysia = "PLACE_Malaysia",
  PLACE_Maldives = "PLACE_Maldives",
  PLACE_Mali = "PLACE_Mali",
  PLACE_Malta = "PLACE_Malta",
  PLACE_Marshall_Islands = "PLACE_Marshall_Islands",
  PLACE_Martinique = "PLACE_Martinique",
  PLACE_Mauritania = "PLACE_Mauritania",
  PLACE_Mauritius = "PLACE_Mauritius",
  PLACE_Mayotte = "PLACE_Mayotte",
  PLACE_Mexico = "PLACE_Mexico",
  PLACE_Micronesia_Federated_States_of = "PLACE_Micronesia_Federated_States_of",
  PLACE_Moldova_Republic_of = "PLACE_Moldova_Republic_of",
  PLACE_Monaco = "PLACE_Monaco",
  PLACE_Mongolia = "PLACE_Mongolia",
  PLACE_Montenegro = "PLACE_Montenegro",
  PLACE_Montserrat = "PLACE_Montserrat",
  PLACE_Morocco = "PLACE_Morocco",
  PLACE_Mozambique = "PLACE_Mozambique",
  PLACE_Myanmar = "PLACE_Myanmar",
  PLACE_Namibia = "PLACE_Namibia",
  PLACE_Nauru = "PLACE_Nauru",
  PLACE_Nepal = "PLACE_Nepal",
  PLACE_Netherlands = "PLACE_Netherlands",
  PLACE_New_Caledonia = "PLACE_New_Caledonia",
  PLACE_New_Zealand = "PLACE_New_Zealand",
  PLACE_Nicaragua = "PLACE_Nicaragua",
  PLACE_Niger = "PLACE_Niger",
  PLACE_Nigeria = "PLACE_Nigeria",
  PLACE_Niue = "PLACE_Niue",
  PLACE_Norfolk_Island = "PLACE_Norfolk_Island",
  PLACE_Northern_Mariana_Islands = "PLACE_Northern_Mariana_Islands",
  PLACE_Norway = "PLACE_Norway",
  PLACE_Oman = "PLACE_Oman",
  PLACE_Pakistan = "PLACE_Pakistan",
  PLACE_Palau = "PLACE_Palau",
  PLACE_Palestinian_Territory_Occupied = "PLACE_Palestinian_Territory_Occupied",
  PLACE_Panama = "PLACE_Panama",
  PLACE_Papua_New_Guinea = "PLACE_Papua_New_Guinea",
  PLACE_Paraguay = "PLACE_Paraguay",
  PLACE_Peru = "PLACE_Peru",
  PLACE_Philippines = "PLACE_Philippines",
  PLACE_Pitcairn = "PLACE_Pitcairn",
  PLACE_Poland = "PLACE_Poland",
  PLACE_Portugal = "PLACE_Portugal",
  PLACE_Puerto_Rico = "PLACE_Puerto_Rico",
  PLACE_Qatar = "PLACE_Qatar",
  PLACE_Réunion = "PLACE_Réunion",
  PLACE_Romania = "PLACE_Romania",
  PLACE_Russian_Federation = "PLACE_Russian_Federation",
  PLACE_Rwanda = "PLACE_Rwanda",
  PLACE_Saint_Barthélemy = "PLACE_Saint_Barthélemy",
  PLACE_Saint_Helena_Ascension_and_Tristan_da_Cunha = "PLACE_Saint_Helena_Ascension_and_Tristan_da_Cunha",
  PLACE_Saint_Kitts_and_Nevis = "PLACE_Saint_Kitts_and_Nevis",
  PLACE_Saint_Lucia = "PLACE_Saint_Lucia",
  PLACE_Saint_Martin_French_part = "PLACE_Saint_Martin_French_part",
  PLACE_Saint_Pierre_and_Miquelon = "PLACE_Saint_Pierre_and_Miquelon",
  PLACE_Saint_Vincent_and_the_Grenadines = "PLACE_Saint_Vincent_and_the_Grenadines",
  PLACE_Samoa = "PLACE_Samoa",
  PLACE_San_Marino = "PLACE_San_Marino",
  PLACE_Sao_Tome_and_Principe = "PLACE_Sao_Tome_and_Principe",
  PLACE_Saudi_Arabia = "PLACE_Saudi_Arabia",
  PLACE_Senegal = "PLACE_Senegal",
  PLACE_Serbia = "PLACE_Serbia",
  PLACE_Seychelles = "PLACE_Seychelles",
  PLACE_Sierra_Leone = "PLACE_Sierra_Leone",
  PLACE_Singapore = "PLACE_Singapore",
  PLACE_Sint_Maarten_Dutch_part = "PLACE_Sint_Maarten_Dutch_part",
  PLACE_Slovakia = "PLACE_Slovakia",
  PLACE_Slovenia = "PLACE_Slovenia",
  PLACE_Solomon_Islands = "PLACE_Solomon_Islands",
  PLACE_Somalia = "PLACE_Somalia",
  PLACE_South_Africa = "PLACE_South_Africa",
  PLACE_South_Georgia_and_the_South_Sandwich_Islands = "PLACE_South_Georgia_and_the_South_Sandwich_Islands",
  PLACE_South_Sudan = "PLACE_South_Sudan",
  PLACE_Spain = "PLACE_Spain",
  PLACE_Sri_Lanka = "PLACE_Sri_Lanka",
  PLACE_Sudan = "PLACE_Sudan",
  PLACE_Suriname = "PLACE_Suriname",
  PLACE_Svalbard_and_Jan_Mayen = "PLACE_Svalbard_and_Jan_Mayen",
  PLACE_Swaziland = "PLACE_Swaziland",
  PLACE_Sweden = "PLACE_Sweden",
  PLACE_Switzerland = "PLACE_Switzerland",
  PLACE_Syrian_Arab_Republic = "PLACE_Syrian_Arab_Republic",
  PLACE_Taiwan_Province_of_China = "PLACE_Taiwan_Province_of_China",
  PLACE_Tajikistan = "PLACE_Tajikistan",
  PLACE_Tanzania_United_Republic_of = "PLACE_Tanzania_United_Republic_of",
  PLACE_Thailand = "PLACE_Thailand",
  PLACE_Timor_Leste = "PLACE_Timor_Leste",
  PLACE_Togo = "PLACE_Togo",
  PLACE_Tokelau = "PLACE_Tokelau",
  PLACE_Tonga = "PLACE_Tonga",
  PLACE_Trinidad_and_Tobago = "PLACE_Trinidad_and_Tobago",
  PLACE_Tunisia = "PLACE_Tunisia",
  PLACE_Turkey = "PLACE_Turkey",
  PLACE_Turkmenistan = "PLACE_Turkmenistan",
  PLACE_Turks_and_Caicos_Islands = "PLACE_Turks_and_Caicos_Islands",
  PLACE_Tuvalu = "PLACE_Tuvalu",
  PLACE_Uganda = "PLACE_Uganda",
  PLACE_Ukraine = "PLACE_Ukraine",
  PLACE_United_Arab_Emirates = "PLACE_United_Arab_Emirates",
  PLACE_United_Kingdom = "PLACE_United_Kingdom",
  PLACE_United_States = "PLACE_United_States",
  PLACE_United_States_Minor_Outlying_Islands = "PLACE_United_States_Minor_Outlying_Islands",
  PLACE_Uruguay = "PLACE_Uruguay",
  PLACE_Uzbekistan = "PLACE_Uzbekistan",
  PLACE_Vanuatu = "PLACE_Vanuatu",
  PLACE_Venezuela_Bolivarian_Republic_of = "PLACE_Venezuela_Bolivarian_Republic_of",
  PLACE_Viet_Nam = "PLACE_Viet_Nam",
  PLACE_Virgin_Islands_British = "PLACE_Virgin_Islands_British",
  PLACE_Virgin_Islands_US = "PLACE_Virgin_Islands_US",
  PLACE_Wallis_and_Futuna = "PLACE_Wallis_and_Futuna",
  PLACE_Western_Sahara = "PLACE_Western_Sahara",
  PLACE_Yemen = "PLACE_Yemen",
  PLACE_Zambia = "PLACE_Zambia",
  PLACE_Zimbabwe = "PLACE_Zimbabwe",

  // Validation
  VALIDATION_EMAIL = "VALIDATION_EMAIL",
  VALIDATION_PHONE_NUMBER = "VALIDATION_PHONE_NUMBER",
  VALIDATION_MIN_LENGTH = "VALIDATION_MIN_LENGTH",
  VALIDATION_MAX_LENGTH = "VALIDATION_MAX_LENGTH",
  VALIDATION_MIN_AMOUNT = "VALIDATION_MIN_AMOUNT",
  VALIDATION_MAX_AMOUNT = "VALIDATION_MAX_AMOUNT",  
}
