import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IPriceQuoteState, IPriceQuoteAction } from "../../interfaces";

const initialState: IPriceQuoteState = {
  priceQuote: null,
  loading: false,
  error: null,
};

const priceQuoteInsuranceStart = (state: IPriceQuoteState, action: IPriceQuoteAction): IPriceQuoteState => {
  return updateObject(state, {
    loading: true,
    error: null,
    priceQuote: null
  });
};

const priceQuoteInsuranceSuccess = (state: IPriceQuoteState, action: IPriceQuoteAction): IPriceQuoteState => {
  return updateObject(state, {
    loading: false,
    error: null,
    priceQuote: action.priceQuote
  });
};

const priceQuoteInsuranceFail = (state: IPriceQuoteState, action: IPriceQuoteAction): IPriceQuoteState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearPriceQuote = (state: IPriceQuoteState, action: IPriceQuoteAction): IPriceQuoteState => {
  return { ...initialState };
};

const reducer = (
  state: IPriceQuoteState = initialState,
  action: IPriceQuoteAction
): IPriceQuoteState => {
  switch (action.type) {
    case ActionTypes.PRICEQUOTE_INSURANCE_START:
      return priceQuoteInsuranceStart(state, action);
    case ActionTypes.PRICEQUOTE_INSURANCE_SUCCESS:
      return priceQuoteInsuranceSuccess(state, action);
    case ActionTypes.PRICEQUOTE_INSURANCE_FAIL:
      return priceQuoteInsuranceFail(state, action);
    case ActionTypes.CLEAR_PRICEQUOTE:
      return clearPriceQuote(state, action);
    default:
      return state;
  }
};

export default reducer;