import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo_desktop.png";
import { ERoute } from "../../classes/Routes";
import HeaderContext from "../../context/HeaderContext";
import Button from "../UI/Button/Button";
import classes from "./Navigation.module.scss";

const Navigation: React.FC = () => {
  const { authUser, onLogout } = useContext(HeaderContext);
  const location = useLocation();

  return (
    <nav className={classes.NavBar}>
      <NavLink
        to={
          location.pathname === ERoute.EXPRESS_CHECKOUT
            ? ERoute.EXPRESS_CHECKOUT
            : location.pathname.startsWith("/customer")
            ? location
            : ERoute.INSURANCES
        }
      >
        <img src={Logo} alt="Touring Cars" className={classes.Logo} />
      </NavLink>
      <div>
        {authUser ? (
          <span>{`${authUser.firstName} ${authUser.lastName}`}</span>
        ) : null}
        {onLogout && (
          <Button
            onClick={onLogout}
            aria-label="Logout"
            className={classes.Logout}
            icon={faSignOutAlt}
          />
        )}
      </div>
    </nav>
  );
};

export default Navigation;
