import React from 'react';
import { TInputValue } from './../components/UI/Input/Input';


export enum EInputUpdateAction {
  AUTH = "AUTH",
  EXPRESS_CHECKOUT = "EXPRESS_CHECKOUT",  
  INSURANCE = "INSURANCE"
}

const InputContext = React.createContext<{
  onAutoUpdate: (inputName: string, value: TInputValue, action: EInputUpdateAction, data?: Object) => void;
  initDone: boolean;
}>({
  onAutoUpdate: (inputName, value, action, data) => {},
  initDone: false
});

export default InputContext;
