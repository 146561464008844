import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Logo from "../../assets/images/logo_desktop.png";
import { ERoute } from "../../classes/Routes";
import Alert from "../../components/UI/Alert/Alert";
import Button, { EButtonType } from "../../components/UI/Button/Button";
import Input, {
  EInputType,
  IInputField,
  IInputOptions,
} from "../../components/UI/Input/Input";
import Spinner from "../../components/UI/Spinner/Spinner";
import { EInputUpdateAction } from "../../context/InputContext";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";
import { updateInputHandler, validateInputs } from "../../shared/utility";
import * as actions from "../../store/actions";
import { ETranslation } from "../../translations/translation-keys";
import classes from "./Auth.module.scss";

export enum EAuth {
  email = "email",
  irn = "irn",
  password = "password",
}

const Auth: React.FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [isValid, setIsValid] = useState(false);

  const { error, loading } = useAppSelector((state) => state.auth);

  const isAdminLogin = location.pathname === ERoute.INSURANCE_ADMIN_LOGIN;

  const [inputs, setInputs] = useState<IInputField>({
    [EAuth.email]: {
      type: EInputType.email,
      labelTranslation: ETranslation.EMAIL,
      value: "",
      validation: {
        required: true,
      },
    },
    [EAuth.irn]: {
      type: EInputType.text,
      labelTranslation: ETranslation.IRN,
      value: "",
      validation: {
        required: true,
      },
    },
    [EAuth.password]: {
      type: EInputType.password,
      labelTranslation: ETranslation.PASSWORD,
      value: "",
      validation: {
        required: true,
      },
    },
  });

  useEffect(() => {
    document.body.classList.add("login-bg");
    return () => {
      document.body.classList.remove("login-bg");
    };
  }, []);

  useEffect(() => {
    setIsValid(validateInputs(inputs));
  }, [inputs]);

  const createAuthInput = (inputName: EAuth, options?: IInputOptions) => {
    const item = inputs[inputName];
    return (
      <Input
        {...item}
        {...options}
        updateAction={EInputUpdateAction.AUTH}
        onChange={(value) => updateInputHandler(inputName, value, setInputs)}
        inputName={inputName}
        showValidation={!isValid}
      />
    );
  };

  const submitHandler = (
    event:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.FormEvent<HTMLFormElement>
  ) => {
    event.preventDefault();
    const email = inputs[EAuth.email].value as string;

    if (isAdminLogin) {
      const password = inputs[EAuth.password].value as string;
      dispatch(actions.login(email, password));
    } else {
      const irn = inputs[EAuth.irn].value as string;
      dispatch(actions.auth(email, irn));
    }
  };

  return (
    <div className={classes.Container}>
      <div className={classes.LogoContainer}>
        <img src={Logo} alt="Touring Cars" className={classes.Logo} />
      </div>
      <div className={classes.LoginBox}>
        <div className={classes.Title}>{t(ETranslation.LOG_IN)}</div>
        {(loading && <Spinner />) || (
          <form onSubmit={submitHandler}>
            <div className={classes.Inputs}>
              {createAuthInput(EAuth.email, { showValidation: true })}
              {isAdminLogin
                ? createAuthInput(EAuth.password, { showValidation: true })
                : createAuthInput(EAuth.irn, { showValidation: true })}
            </div>
            <div className={classes.ButtonContainer}>
              <Button
                onClick={submitHandler}
                className={classes.Button}
                type={EButtonType.SUBMIT}
              >
                {t(ETranslation.LOG_IN)}
              </Button>
            </div>
            {error && <Alert className={classes.ErrorMessage} text={error} />}
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;
