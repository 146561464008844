import { ActionTypes } from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";
import { IInsuranceState, IInsuranceAction } from "../../interfaces";

const initialState: IInsuranceState = {
  insurance: null,
  insurances: null,
  loading: false,
  error: null,
  saveOrUpdateOk: false
};

const getInsuranceStart = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, { 
    error: null, 
    loading: true, 
    insurance: null });
};

const getInsuranceSuccess = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    insurance: action.insurance,
    error: null,
    loading: false,
  });
};

const getInsuranceFail = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const listInsurancesStart = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: null, 
    loading: true, 
    insurances: null 
  });
};

const listInsurancesSuccess = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    insurances: action.insurances,
    error: null,
    loading: false,
  });
};

const listInsurancesFail = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const clearInsurance = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return { ...initialState };
};

const cancelInsuranceStart = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, { 
    error: null, 
    loading: true, 
    insurances: null 
  });
};

const cancelInsuranceSuccess = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: null,
    loading: false,
    saveOrUpdateOk: true
  });
};

const cancelInsuranceFail = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const saveInsuranceStart = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const saveInsuranceSuccess = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const saveInsuranceFail = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};


const amendInsuranceStart = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    loading: true,
    error: null,
  });
};

const amendInsuranceSuccess = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    loading: false,
    error: null,
    saveOrUpdateOk: true
  });
};

const amendInsuranceFail = (state: IInsuranceState, action: IInsuranceAction): IInsuranceState => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

const reducer = (
  state: IInsuranceState = initialState,
  action: IInsuranceAction
): IInsuranceState => {
  switch (action.type) {
    case ActionTypes.GET_INSURANCE_START:
      return getInsuranceStart(state, action);
    case ActionTypes.GET_INSURANCE_SUCCESS:
      return getInsuranceSuccess(state, action);
    case ActionTypes.GET_INSURANCE_FAIL:
      return getInsuranceFail(state, action);
    case ActionTypes.LIST_INSURANCES_START:
      return listInsurancesStart(state, action);
    case ActionTypes.LIST_INSURANCES_SUCCESS:
      return listInsurancesSuccess(state, action);
    case ActionTypes.LIST_INSURANCES_FAIL:
      return listInsurancesFail(state, action);
    case ActionTypes.CLEAR_INSURANCE:
      return clearInsurance(state, action);
    case ActionTypes.CANCEL_INSURANCE_START:
      return cancelInsuranceStart(state, action);
    case ActionTypes.CANCEL_INSURANCE_SUCCESS:
      return cancelInsuranceSuccess(state, action);
    case ActionTypes.CANCEL_INSURANCE_FAIL:
      return cancelInsuranceFail(state, action);
    case ActionTypes.SAVE_INSURANCE_START:
      return saveInsuranceStart(state, action);
    case ActionTypes.SAVE_INSURANCE_SUCCESS:
      return saveInsuranceSuccess(state, action);
    case ActionTypes.SAVE_INSURANCE_FAIL:
      return saveInsuranceFail(state, action);
    case ActionTypes.AMEND_INSURANCE_START:
      return amendInsuranceStart(state, action);
    case ActionTypes.AMEND_INSURANCE_SUCCESS:
      return amendInsuranceSuccess(state, action);
    case ActionTypes.AMEND_INSURANCE_FAIL:
      return amendInsuranceFail(state, action);
    default:
      return state;
  }
};

export default reducer;