
export enum ERoute {
  INSURANCE_ADMIN_LOGIN =  "/insuranceadminlogin",
  SELF_CHECKOUT_LOGIN = "/selfcheckoutlogin",
  INSURANCES = "/insurances",
  INSURANCE = "/insurances/:id",
  EXPRESS_CHECKOUT = "/checkout",
  CUSTOMER_CHECKOUT = "/customer",
  CUSTOMER_ERROR = "/customer/error",
}

export enum ERouteStaticParams {
  new = 'new'
}

type TParam = string | ERouteStaticParams;

interface IParams {
  [key: string]: TParam;
}

export class Routes {
  public static INSURANCE_ADMIN_LOGIN = ERoute.INSURANCE_ADMIN_LOGIN;
  public static SELF_CHECKOUT_LOGIN = ERoute.SELF_CHECKOUT_LOGIN;
  public static INSURANCES = ERoute.INSURANCES;
  public static INSURANCE = (id: TParam) => Routes.withParams(ERoute.INSURANCE, { id });
  public static EXPRESS_CHECKOUT = ERoute.EXPRESS_CHECKOUT;
  public static CUSTOMER_CHECKOUT = ERoute.CUSTOMER_CHECKOUT;
  public static CUSTOMER_ERROR = ERoute.CUSTOMER_ERROR;

  public static withParams(route: ERoute, params: IParams): string {
    let routeWithParams: string = route;
    for(let key in params) {
      routeWithParams = routeWithParams.replace(`:${key}`, params[key] as string);
    }
    return routeWithParams;
  }

  public static isNew(id: TParam): boolean {
    return id === ERouteStaticParams.new;
  }

  public static isNotNew(id: TParam): boolean {
    return id !== ERouteStaticParams.new;
  }

}
