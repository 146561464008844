import React, { CSSProperties } from "react";
import classes from "./Alert.module.scss";

interface IProps {
  text: string;
  className?: string;
  style?: CSSProperties;
}

const Alert: React.FC<IProps> = ({ text, className, style }) => {
  const classNames = [classes.Container];
  if (className) {
    classNames.push(className);
  }
  return <div style={style} className={classNames.join(" ")}>{text}</div>;
};

export default Alert;
