import React from "react";
import Input, { IInputOptions, IInputField } from "../components/UI/Input/Input";
import { EInputUpdateAction } from "../context/InputContext";
import { updateInputHandler } from "../shared/utility";

export type TCreateInput = (inputName: string, options?: IInputOptions) => JSX.Element;

export const useCreateInput = (inputs: IInputField, setInputs: React.Dispatch<React.SetStateAction<IInputField>>, updateAction?: EInputUpdateAction, commonOptions?: IInputOptions, ) => {
    const createInput: TCreateInput = (inputName: string, options?: IInputOptions) => {
      const item = inputs[inputName];
      return (
        <Input
          {...commonOptions}
          {...item}
          {...options}
          updateAction={updateAction}
          onChange={(value) => updateInputHandler(inputName, value, setInputs)}
          inputName={inputName}
        />
      );
    }; 
    return createInput;
}